@import "../../../common/variables/variables.scss";

.pressure-banner {
  height: 80vh;
  // background-image: url(../../../../Assets/Images/Banner/);
  background-size: cover;
  background-position: center;
  // @media (max-width: 1200px) {
  //   background-position: 70% 0%;
  // }

  .container {
    padding-top: 30rem;
    padding-left: 10rem;
    @media (max-width: 800px) {
      padding-left: 3rem;
      padding-top: 27rem;
    }
    h1 {
      font-size: 4.5rem;
      color: white;
      text-shadow: 3px 3px 1px black;
      @media (max-width: 800px) {
        font-size: 3.2rem;
      }
    }

    h3 {
      margin-top: 1rem;
      font-size: 3.5rem;
      color: white;
      text-shadow: 3px 3px 1px black;
      margin-bottom: 2rem;
      @media (max-width: 800px) {
        font-size: 2.5rem;
      }
    }

    .rug-contact {
      display: flex;
      align-items: center;
      color: white;
      border: 1px solid rgb(1, 137, 177);
      border-radius: 4rem;
      width: fit-content;

      &:hover {
        animation-name: btn1;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
      }

      span {
        font-size: 2.5rem;
        margin-right: 2rem;
        margin-left: 1rem;
        font-weight: 600;
        text-shadow: 2px 2px 2px black;
      }
      img {
        width: 7rem;
        height: 5rem;
      }
    }
  }
}

.pressure {
  margin-top: 5rem;

  .pressure-content {
    padding: 5rem 13%;
    margin-bottom: -8rem;

    @media (max-width: 1200px) {
      padding: 5rem 7%;
    }
    @media (max-width: 800px) {
      padding: 5rem 2rem;
    }

    .main-content {
      display: flex;
      flex-wrap: wrap;
      gap: 2rem;
      margin-bottom: 5rem;
      .heading {
        width: 48%;
        @media (max-width: 800px) {
          width: 100%;
        }
        h2 {
          font-size: 3.5rem;
          color: $main-l;
          margin-bottom: 0.5rem;
        }
        h3 {
          color: black;
          margin-bottom: 0.5rem;
        }
        p {
          margin-top: 1rem;
          text-align: justify;
        }

        .buttons {
          margin-top: 2rem;
          display: flex;
          gap: 2rem;
          // justify-content: center;
          a {
            font-size: 2.5rem;
            background-color: $main-l;
            padding: 0.8rem 1.3rem;
            color: white;
            cursor: pointer;
            transition: all ease 0.5s;

            &:hover {
              background-color: greenyellow;
            }
          }
        }
      }

      .img {
        width: 48%;
        @media (max-width: 800px) {
          width: 100%;
        }
      }
    }
  }
}
