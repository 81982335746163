@import "../../common/variables/variables.scss";

.fquote {
  padding: 5rem 15%;

  @media (max-width: 600px) {
    font-size: 100%;
  }

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .heading {
    h1 {
      color: $main-l;
    }
    margin-bottom: 2rem;
  }

  .info {
    .info-head {
      display: flex;
      .head {
        font-size: 3.2rem;
        cursor: pointer;
        width: fit-content;
        padding: 1rem 3rem;
        transition: all 1s ease;
        &.active {
          background-color: #eee;
        }
      }
    }
    .info-content {
      background-color: #eee;
      padding: 1rem;

      .main-heading {
        font-size: 2.7rem;
        font-weight: 400;
        color: black;
      }
      .size {
        margin-bottom: 1rem;

        .size-content {
          margin: 2rem 0;
          max-width: 40rem;
          input {
            font-size: 2rem;
            width: 100%;
            padding: 1rem;
          }
        }

        .size-wrap {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

          .size-info {
            margin: 2rem;

            input {
              font-size: 2rem;
              padding: 0.5rem 0.5rem;
              width: 8rem;
            }

            .wid {
              display: flex;
              align-items: center;
              gap: 2rem;

              .icon {
                position: relative;
                .cross-wrap {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  cursor: pointer;
                  position: absolute;
                  left: -8px;
                  top: 10px;
                  background-color: #d25252;
                  //   padding: 1rem;
                  height: 3rem;
                  width: 3rem;
                  border-radius: 50%;
                  .cross {
                    font-size: 2rem;
                    color: white;
                  }
                }
                .rugicon {
                  font-size: 16rem;
                }
              }

              .inp {
                display: flex;
                flex-direction: column;
                gap: 1rem;

                span {
                  font-size: 1.8rem;
                }
              }
            }

            .len {
              display: flex;
              flex-direction: column;
              gap: 2rem;
              .input {
                display: flex;
                gap: 1rem;
                align-items: center;
                span {
                  font-size: 1.8rem;
                }
              }
              .type {
                display: flex;
                align-items: center;
                gap: 1rem;
                span {
                  font-size: 1.8rem;
                }

                select {
                  font-size: 1.8rem;
                  padding: 0.5rem;
                  outline: none;
                  line-height: 1.8rem;

                  option {
                    font-size: 1.5rem;
                  }
                }
              }
            }
          }
        }
        button {
          font-size: 2rem;
          color: $main-l;
          border: solid 1px $main-l;
          cursor: pointer;
          margin: 2rem;
          transition: 0.5s all ease;
          &:hover {
            background-color: $main-l;
            color: #eee;
          }
        }
      }

      .about {
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        gap: 2rem;

        .buttons {
          display: flex;
          align-items: center;
          gap: 2rem;

          button {
            font-size: 2rem;
            color: $main-l;
            border: solid 1px $main-l;
            cursor: pointer;
            transition: 0.5s all ease;
            &:hover {
              background-color: $main-l;
              color: #eee;
            }
          }
        }
        .message {
          max-width: 60rem;
          textarea {
            width: 100%;
            //   min-width: 60rem;
            height: 10rem;
            padding: 1rem;
            align-self: flex-start;
            background-color: transparent;
            font-size: 2rem;
          }
        }
      }

      .delivery {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin-bottom: 3rem;
        span {
          font-size: 1.8rem;
        }

        .radio {
          display: flex;
          align-items: center;
          position: relative;
          @media (max-width: 600px) {
            align-items: start;
            flex-direction: column;
          }
          gap: 3rem;
          label {
            font-size: 2rem;
            display: flex;
            align-items: center;
            gap: 0.5rem;
            input {
              color: $main-l;
            }
            input[type="radio"] {
              accent-color: $main-l;
            }
          }
        }
        hr {
          margin-top: 10px;
          margin-bottom: 10px;
          border: 0;
          border-top: 1px solid $greenyellow;
        }
        .check {
          label {
            font-size: 2rem;
            display: flex;
            gap: 0.5rem;
            input {
              color: $main-l;
            }
            input[type="checkbox"] {
              accent-color: $main-l;
            }
          }
        }
      }

      .appointment {
        max-width: 40rem;
        margin-bottom: 3rem;
        font-size: 100% !important;
        @media (max-width: 1050px) {
          font-size: 100%;
        }

        @media (max-width: 450px) {
          font-size: 100%;
        }

        input {
          font-size: 2rem;
          background-color: white;
        }
        .font {
          font-size: 100% !important;
        }
      }

      .contact {
        .contact-form {
          margin: 2rem;
          display: flex;
          flex-direction: column;
          gap: 2rem;
          label {
            color: #d25252;
            display: flex;
            align-items: center;
            font-size: 2rem;
            @media (max-width: 500px) {
              align-items: flex-start;
              gap: 1.5rem;
              flex-direction: column;
            }
            span {
              color: black;
              width: 20rem;
              font-size: 2rem;
            }
            input {
              font-size: 2rem;
              width: 100%;
              padding: 1rem;
            }
          }
        }
      }

      .submit {
        padding: 2rem;
        button {
          font-size: 3rem;
          font-weight: 300;
          color: $main-l;
          border: solid 1px $main-l;
          cursor: pointer;
          transition: 0.5s all ease;
          &:hover {
            background-color: $main-l;
            color: #eee;
          }
        }
      }
    }
  }
}
