/* @import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap"); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-display: swap;
  font-family: 'Lato', sans-serif;
  font-size: 62.5%;
}

@font-face {
  font-family: 'Lato';
  src: url('https://fonts.gstatic.com/s/lato/v20/S6uyw4BMUTPHjx4wXiWtFCc.woff2') format('woff2');
  font-display: swap;
}





@media (max-width: 1050px) {
  html {
    font-size: 55%;
  }
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
}


button {
  padding: 1rem;
}

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit;
}


::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}


input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.css-6exafu,
.css-15a0tn7.Mui-selected,
.css-15a0tn7:not(.Mui-selected),
.css-10qkmc3,
.css-1v994a0,
.css-10qkmc3,
.css-1flhz3h,
.css-1bzq5ag,
.css-19xycpm,
.css-rhiqj0,
.css-1cw4hi4,
.css-1ujsas3,
.css-vubbuv,
.css-1ox9k5z {
  font-size: 1.6rem !important;
}