h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.thank {
  text-align: center;
  margin: 5rem 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.thank h1 {
  color: #008f34;
  font-size: 5rem;
  margin-bottom: 0.5rem;
}

.thank .buttons {
  margin-top: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}

.thank .buttons a {
  color: #008f34;
  font-size: 2.5rem;
  padding: 0.5rem 1rem;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.thank .buttons a:hover {
  background-color: #008f34;
  color: white;
}
