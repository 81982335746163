@import "../../common/variables/variables.scss";

.frequency {
  background-image: url(../../../Assets/Images/Banner/map-banner.webp);
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  text-align: center;
  h1 {
    color: $main-l;
    font-size: 3.5rem;
  }

  h2 {
    color: #000;
    margin-bottom: 2rem;
    font-size: 4rem;
  }

  p {
    margin-bottom: 4rem;
  }

  .frequency-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;

    .frequency-box {
      img {
        width: 100%;
        margin-bottom: 4rem;
      }

      h2 {
        font-size: 2.3rem;
        color: $main-l;
      }
    }
  }
}
