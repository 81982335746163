h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.reviews .slide p {
  padding: 1.5rem;
  padding-bottom: 4rem;
  background: #eee;
  position: relative;
  margin-bottom: 3rem;
  font-size: 1.6rem;
  color: #666;
  line-height: 2;
}

.reviews .heading {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  background-color: #4a4a4c;
  border-bottom: 5px solid #008f34;
  margin-bottom: 7rem;
}

.reviews .heading h1 {
  font-size: 4rem;
  padding: 4rem 2rem;
  color: white;
}

.reviews .heading img {
  width: 10rem;
  height: 7rem;
  margin-right: 5rem;
}

.reviews .review-content {
  grid-gap: 4rem;
  display: -ms-grid;
  display: grid;
  gap: 4rem;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  padding: 2rem 10rem;
  margin-bottom: 3rem;
}

@media (max-width: 800px) {
  .reviews .review-content {
    padding: 2rem 2rem;
    gap: 4rem;
  }
}

.reviews .review-content .content-box {
  text-align: center;
}

.reviews .review-content .content-box p {
  font-size: 2.5rem;
  min-height: 25rem;
}

@media (max-width: 650px) {
  .reviews .review-content .content-box p {
    padding-bottom: 1rem;
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
  }
}

.reviews .review-content .content-box span {
  color: #6ec1e4;
  font-size: 2.3rem;
}

.reviews .review-content .content-box .icons {
  margin-top: 1rem;
}

.reviews .review-content .content-box .icons svg {
  font-size: 2.2rem;
  color: gold;
}

.reviews a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%;
}

.reviews a .more-review {
  background-color: #008f34;
  padding: 2rem 2rem;
  margin-top: 1rem;
  border-bottom-left-radius: 3rem;
  border-top-right-radius: 3rem;
  text-align: center;
}

.reviews a .more-review span {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: white;
  text-align: center;
  font-size: 2.5rem;
}

.reviews .slide p::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 2rem;
  height: 2rem;
  width: 2rem;
  background: #eee;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.reviews .slide .user {
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.5rem;
}

.reviews .slide .user img {
  height: 7rem;
  width: 7rem;
}

.reviews .slide .user h3 {
  font-size: 2.5rem;
  color: #eee;
  text-transform: capitalize;
}

.reviews .slide .user .stars {
  padding-top: 0.5rem;
}

.reviews .slide .user .stars i {
  font-size: 1.4rem;
  color: #f5bf23;
}
