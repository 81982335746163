h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.change-slide {
  background: url(../../../Assets/Images/NDIS/ndis-banner.webp);
  background-size: cover;
  background-position: center;
  padding: 5rem 10%;
}

@media (max-width: 1200px) {
  .change-slide {
    padding: 5rem 3%;
  }
}

@media (max-width: 800px) {
  .change-slide {
    padding: 5rem 2rem;
  }
}

.change-slide .container-1 {
  margin-bottom: 4rem;
}

.change-slide .container-1 h2 {
  text-align: center;
  font-size: 4rem;
  color: #008f34;
  margin-bottom: 2rem;
}

.change-slide .container-1 p {
  text-align: center;
}

.change-slide .container-2 h2 {
  text-align: center;
  font-size: 4rem;
  color: #008f34;
  margin-bottom: 2rem;
}

.change-slide .container-3 .switch {
  margin-bottom: 4rem;
  gap: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.change-slide .container-3 .switch .box {
  cursor: pointer;
  min-width: 17rem;
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 0.5rem;
  padding: 2rem;
}

.change-slide .container-3 .switch .box img {
  width: 8rem;
  height: 7rem;
  margin-bottom: 2rem;
}

@media (max-width: 600px) {
  .change-slide .container-3 .switch .box img {
    width: 6rem;
    height: 5rem;
  }
}

.change-slide .container-3 .switch .box p {
  text-align: center;
  color: black;
}

.change-slide .container-3 .switch .box.active {
  background-color: #c2db5d;
}

.change-slide .container-3 .content {
  margin: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background-color: #008f34;
}

@media (max-width: 600px) {
  .change-slide .container-3 .content {
    margin: 0;
  }
}

.change-slide .container-3 .content .info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  padding: 5rem 4rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.change-slide .container-3 .content .info h2 {
  text-align: center;
  color: white;
  margin-bottom: 2rem;
}

.change-slide .container-3 .content .info p {
  color: white;
  text-align: center;
  margin-bottom: 3rem;
}

.change-slide .container-3 .content .info a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid white;
  border-radius: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.change-slide .container-3 .content .info a:hover {
  -webkit-animation-name: btn1;
          animation-name: btn1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.change-slide .container-3 .content .info a span {
  font-size: 2.2rem;
  margin-right: 2rem;
  margin-left: 1rem;
  color: white;
}

.change-slide .container-3 .content .info a img {
  width: 7rem;
  height: 5rem;
}

.change-slide .container-3 .content .img {
  -webkit-clip-path: circle(65% at 65% 50%);
          clip-path: circle(65% at 65% 50%);
}

@media (max-width: 1080px) {
  .change-slide .container-3 .content .img {
    display: none;
  }
}

.change-slide .container-3 .content .img img {
  max-height: 50rem;
}

.ndis-main-info {
  padding: 5rem 13%;
}

@media (max-width: 1200px) {
  .ndis-main-info {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .ndis-main-info {
    padding: 5rem 2rem;
  }
}

.ndis-main-info .container-1-wrapper,
.ndis-main-info .container-3-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 3rem;
  margin-bottom: 3rem;
}

@media (max-width: 500px) {
  .ndis-main-info .container-1-wrapper,
  .ndis-main-info .container-3-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
  }
}

.ndis-main-info .container-1-wrapper .container-1 .heading,
.ndis-main-info .container-1-wrapper .container-3 .heading,
.ndis-main-info .container-3-wrapper .container-1 .heading,
.ndis-main-info .container-3-wrapper .container-3 .heading {
  margin-bottom: 2rem;
}

.ndis-main-info .container-1-wrapper .container-1 .heading p,
.ndis-main-info .container-1-wrapper .container-3 .heading p,
.ndis-main-info .container-3-wrapper .container-1 .heading p,
.ndis-main-info .container-3-wrapper .container-3 .heading p {
  font-weight: 550;
}

.ndis-main-info .container-1-wrapper .container-1 .content,
.ndis-main-info .container-1-wrapper .container-3 .content,
.ndis-main-info .container-3-wrapper .container-1 .content,
.ndis-main-info .container-3-wrapper .container-3 .content {
  display: inline;
}

.ndis-main-info .container-1-wrapper .container-1 .content span,
.ndis-main-info .container-1-wrapper .container-3 .content span,
.ndis-main-info .container-3-wrapper .container-1 .content span,
.ndis-main-info .container-3-wrapper .container-3 .content span {
  font-size: inherit;
  color: #008f34;
}

.ndis-main-info .container-1-wrapper .container-1 .content img,
.ndis-main-info .container-1-wrapper .container-3 .content img,
.ndis-main-info .container-3-wrapper .container-1 .content img,
.ndis-main-info .container-3-wrapper .container-3 .content img {
  width: 30rem;
  float: right;
  padding: 2rem;
}

@media (max-width: 500px) {
  .ndis-main-info .container-1-wrapper .container-1 .content img,
  .ndis-main-info .container-1-wrapper .container-3 .content img,
  .ndis-main-info .container-3-wrapper .container-1 .content img,
  .ndis-main-info .container-3-wrapper .container-3 .content img {
    float: right;
    padding-left: 2rem;
    width: 100%;
  }
}

.ndis-main-info .container-1-wrapper .container-1 ul,
.ndis-main-info .container-1-wrapper .container-3 ul,
.ndis-main-info .container-3-wrapper .container-1 ul,
.ndis-main-info .container-3-wrapper .container-3 ul {
  margin-left: 3rem;
}

.ndis-main-info .container-1-wrapper .container-3-img,
.ndis-main-info .container-3-wrapper .container-3-img {
  margin-top: 4rem;
}

.ndis-main-info .container-1-wrapper .container-3-img img,
.ndis-main-info .container-3-wrapper .container-3-img img {
  width: 30rem;
}

.ndis-main-info .container-1-wrapper .container-1-img,
.ndis-main-info .container-3-wrapper .container-1-img {
  margin-top: 4rem;
}

@media (max-width: 500px) {
  .ndis-main-info .container-1-wrapper .container-1-img,
  .ndis-main-info .container-3-wrapper .container-1-img {
    margin-top: 0rem;
  }
}

.ndis-main-info .container-2-wrapper {
  margin-bottom: 3rem;
}

.ndis-main-info .container-2-wrapper .heading {
  margin-bottom: 1rem;
}

.ndis-main-info .container-2-wrapper .heading p {
  font-weight: 550;
}

.ndis-img {
  padding: 5rem 15%;
}

@media (max-width: 1200px) {
  .ndis-img {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .ndis-img {
    padding: 5rem 2rem;
  }
}

.ndis-img .img-1 {
  margin-bottom: 3rem;
}

.ndis-img .img-1 img {
  width: 100%;
}

.ndis-img .img-2 img {
  width: 100%;
}

.ndis-img .img-2 h2 {
  color: #008f34;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 2rem;
}

.rug-service {
  padding: 5rem 15%;
  grid-gap: 2rem;
  display: -ms-grid;
  display: grid;
  gap: 2rem;
  -ms-grid-columns: (minmax(30rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

@media (max-width: 1200px) {
  .rug-service {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .rug-service {
    padding: 3rem 2rem;
  }
}

.rug-service .rug-service-img h2 {
  display: none;
  text-align: center;
  margin-bottom: 4rem;
  color: #217e37;
  font-size: 3.5rem;
}

@media (max-width: 580px) {
  .rug-service .rug-service-img h2 {
    display: block;
  }
}

.rug-service .rug-service-img h4 {
  display: none;
  color: black;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}

@media (max-width: 580px) {
  .rug-service .rug-service-img h4 {
    display: block;
  }
}

.rug-service .rug-service-img img {
  width: 100%;
}

.rug-service .rug-service-content h2 {
  margin-top: 2rem;
  margin-bottom: 1rem;
  color: #217e37;
  font-size: 3.5rem;
}

@media (max-width: 580px) {
  .rug-service .rug-service-content h2 {
    display: none;
  }
}

.rug-service .rug-service-content h4 {
  color: black;
  margin-bottom: 1rem;
  font-size: 3.5rem;
}

@media (max-width: 580px) {
  .rug-service .rug-service-content h4 {
    display: none;
  }
}

.rug-service .rug-service-content p {
  margin-bottom: 3rem;
  line-height: 3rem;
}

.rug-service .rug-service-content h3 {
  color: #c2db5d;
  font-size: 3rem;
  font-weight: 500;
}

.rug-service .rug-service-content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid #0189b1;
  border-radius: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.rug-service .rug-service-content a:hover {
  -webkit-animation-name: btn1;
          animation-name: btn1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.rug-service .rug-service-content a span {
  font-size: 2.2rem;
  margin-right: 2rem;
  margin-left: 1rem;
}

.rug-service .rug-service-content a img {
  width: 7rem;
  height: 5rem;
}

.rug-service.reverse {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.rug-service.reverse .rug-service-img h2 {
  display: none;
  text-align: center;
  margin-bottom: 1rem;
  color: #217e37;
  font-size: 3.5rem;
}

@media (max-width: 580px) {
  .rug-service.reverse .rug-service-img h2 {
    display: block;
  }
}

.rug-service.reverse .rug-service-img h4 {
  display: none;
  color: black;
  text-align: center;
  margin-bottom: 1rem;
  font-size: 3.5rem;
  margin-bottom: 3rem;
}

@media (max-width: 580px) {
  .rug-service.reverse .rug-service-img h4 {
    display: block;
  }
}

.rug-service.reverse .rug-service-content h4 {
  color: black;
  margin-bottom: 1rem;
  font-size: 3.5rem;
}

@media (max-width: 580px) {
  .rug-service.reverse .rug-service-content h4 {
    display: none;
  }
}

.rug-service.reverse .rug-service-content p {
  display: inline;
}

@media (max-width: 580px) {
  .rug-service.reverse .rug-service-content p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.rug-service.reverse .rug-service-content p img {
  float: right;
  margin: 5px 1px 5px 0;
  max-width: 30rem;
}

@media (max-width: 580px) {
  .rug-service.reverse .rug-service-content p img {
    max-width: 100%;
    float: left;
  }
}

.stain {
  background: url(../../../Assets/Images/Banner/pet-banner.webp);
  padding: 5rem 15%;
}

@media (max-width: 1200px) {
  .stain {
    padding: 5rem 3%;
  }
}

@media (max-width: 800px) {
  .stain {
    padding: 5rem 2rem;
  }
}

.stain .heading {
  text-align: center;
}

.stain .heading h1 {
  font-size: 3.5rem;
  color: #008f34;
  margin-bottom: 2rem;
}

.stain .heading h2 {
  font-size: 3.5rem;
  margin-bottom: 3rem;
}

.stain .heading p {
  margin-bottom: 4rem;
}

.stain .stain-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 1000px) {
  .stain .stain-wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}

@media (max-width: 700px) {
  .stain .stain-wrapper {
    gap: 2rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.stain .stain-wrapper .main-info {
  margin-left: 17rem;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.stain .stain-wrapper .main-info img {
  max-width: 60rem;
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .main-info img {
    width: 100%;
  }
}

@media (max-width: 1450px) {
  .stain .stain-wrapper .main-info {
    margin-left: 12rem;
  }
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .main-info {
    margin-left: 0rem;
  }
}

.stain .stain-wrapper .icon-info {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

@media (max-width: 800px) {
  .stain .stain-wrapper .icon-info {
    width: 100%;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper {
  position: relative;
  margin-bottom: 1rem;
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 {
  position: relative;
  left: -12rem;
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 {
    top: 0;
    left: 0;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 {
  position: relative;
  left: -8.5rem;
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 {
    top: 0;
    left: 0;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 {
  position: relative;
  left: -16rem;
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 {
    top: 0;
    left: 0;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 {
  position: relative;
  left: -30rem;
  top: -5rem;
}

@media (max-width: 1550px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 {
    top: -3rem;
  }
}

@media (max-width: 1000px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 {
    top: 0;
    left: 0;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1:hover .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2:hover .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3:hover .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4:hover .icon {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .icon,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .icon {
  margin-right: 3rem;
  border-radius: 50%;
  background-color: #c2db5d;
  border: 1rem solid white;
  padding: 3rem;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

@media (max-width: 700px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .icon,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .icon,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .icon,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .icon {
    padding: 2rem;
    border: 0.5rem solid white;
    margin-right: 1rem;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .icon img,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .icon img,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .icon img,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .icon img {
  width: 7rem;
}

@media (max-width: 800px) {
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .icon img,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .icon img,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .icon img,
  .stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .icon img {
    width: 5rem;
  }
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .info h1,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .info h1,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .info h1,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .info h1 {
  color: #008f34;
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box1 .info p,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box2 .info p,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box3 .info p,
.stain .stain-wrapper .icon-info .icon-box-wrapper .icon-box4 .info p {
  line-height: 2.5rem;
}

.threeD {
  background: url(../../../Assets/Images/Banner/3d-banner.webp);
  background-position: center;
  padding: 7rem 0;
}

.threeD .wrapper {
  padding: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

@media (max-width: 600px) {
  .threeD .wrapper {
    padding: 1rem;
  }
}

.threeD .wrapper .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: relative;
  will-change: transform;
  -webkit-transform: perspective(700px) rotateY(5deg);
          transform: perspective(700px) rotateY(5deg);
  -webkit-transition: -webkit-transform 1s ease 0s;
  transition: -webkit-transform 1s ease 0s;
  transition: transform 1s ease 0s;
  transition: transform 1s ease 0s, -webkit-transform 1s ease 0s;
}

.threeD .wrapper .container:hover {
  -webkit-transform: perspective(3000px) rotateY(-5deg);
          transform: perspective(3000px) rotateY(-5deg);
}

.threeD .wrapper .container .comment-box img {
  max-width: 100%;
}

@media (max-width: 400px) {
  .threeD .wrapper .container .comment-box img {
    min-height: 42rem;
  }
}

.threeD .wrapper .container .comment-info {
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: absolute;
  padding: 0rem 1rem;
  top: 5rem;
  max-width: 58rem;
}

.threeD .wrapper .container .comment-info h1 {
  color: #02285e;
  font-size: 7rem;
  font-weight: 900;
}

@media (max-width: 600px) {
  .threeD .wrapper .container .comment-info h1 {
    font-size: 5rem;
  }
}

@media (max-width: 400px) {
  .threeD .wrapper .container .comment-info h1 {
    font-size: 4rem;
  }
}

.threeD .wrapper .container .comment-info p {
  color: black;
  font-size: 2.5rem;
  margin-bottom: 4rem;
}

@media (max-width: 600px) {
  .threeD .wrapper .container .comment-info p {
    margin-bottom: 2rem;
    font-size: 2rem;
  }
}

.threeD .wrapper .container .comment-info button {
  width: 23rem;
  background-color: transparent;
  padding: 0;
  padding-left: 1rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  border: 1px solid #0189b1;
  border-radius: 4rem;
  cursor: pointer;
}

.threeD .wrapper .container .comment-info button:hover {
  -webkit-animation-name: btn1;
          animation-name: btn1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.threeD .wrapper .container .comment-info button span {
  font-size: 2.2rem;
  margin-right: 1rem;
  margin-left: 1rem;
}

.threeD .wrapper .container .comment-info button img {
  width: 7rem;
  height: 5rem;
}

.benefits {
  padding: 5rem 10%;
  width: 100%;
  background-image: url(../../../Assets/Images/Banner/benifits-of-cleaning.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
}

@media (max-width: 1200px) {
  .benefits {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .benefits {
    padding: 5rem 2rem;
  }
}

.benefits .benefits-wrapper {
  padding-left: 40%;
}

@media (max-width: 1200px) {
  .benefits .benefits-wrapper {
    padding-left: 0;
  }
}

.benefits .benefits-wrapper .benefits-heading {
  margin-bottom: 4rem;
}

.benefits .benefits-wrapper .benefits-heading h2 {
  text-transform: uppercase;
  color: #217e37;
  font-size: 3rem;
  margin-bottom: 2rem;
}

.benefits .benefits-wrapper .benefits-heading h1 {
  text-transform: uppercase;
  color: black;
  font-size: 3rem;
}

.benefits .benefits-wrapper .benefits-content {
  grid-gap: 3rem;
  display: -ms-grid;
  display: grid;
  gap: 3rem;
  -ms-grid-columns: (minmax(25rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
}

@media (max-width: 1200px) {
  .benefits .benefits-wrapper .benefits-content {
    -ms-grid-columns: (minmax(35rem, 1fr))[auto-fit];
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
  }
}

.benefits .benefits-wrapper .benefits-content .benefit-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  gap: 2rem;
}

.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  background-color: white;
  padding: 1.5rem;
  min-height: 8rem;
  min-width: 8rem;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 8px 4px rgba(3, 3, 3, 0.2);
          box-shadow: 0px 0px 8px 4px rgba(3, 3, 3, 0.2);
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img:hover {
  -webkit-transform: scale(1.2);
          transform: scale(1.2);
}

.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-img img {
  width: 4rem;
}

.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-info h4 {
  color: #008f34;
  font-size: 2rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
}

.benefits .benefits-wrapper .benefits-content .benefit-box .benefit-info p {
  font-size: 2rem;
}
