h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.frequency {
  background-image: url(../../../Assets/Images/Banner/map-banner.webp);
  padding: 5rem 15%;
  text-align: center;
}

@media (max-width: 1200px) {
  .frequency {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .frequency {
    padding: 5rem 2rem;
  }
}

.frequency h1 {
  color: #008f34;
  font-size: 3.5rem;
}

.frequency h2 {
  color: #000;
  margin-bottom: 2rem;
  font-size: 4rem;
}

.frequency p {
  margin-bottom: 4rem;
}

.frequency .frequency-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  gap: 1rem;
}

.frequency .frequency-content .frequency-box img {
  width: 100%;
  margin-bottom: 4rem;
}

.frequency .frequency-content .frequency-box h2 {
  font-size: 2.3rem;
  color: #008f34;
}
