h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.navbarM {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  -webkit-transition: all ease 1s;
  transition: all ease 1s;
}

@media (max-width: 850px) {
  .navbarM {
    background-color: white;
  }
}

.navbarM .nav-tab {
  padding: 0rem 7%;
  padding-bottom: 1rem;
}

@media (max-width: 1200px) {
  .navbarM .nav-tab {
    padding: 0rem 3%;
    padding-bottom: 1rem;
  }
}

@media (max-width: 800px) {
  .navbarM .nav-tab {
    padding: 0rem 2rem;
    padding-bottom: 1rem;
  }
}

.navbarM .navbar_wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  padding: 1rem 0 0 0rem;
}

@media (max-width: 900px) {
  .navbarM .navbar_wrapper {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 0.5rem 0 0 0rem;
  }
}

.navbarM .navbar_wrapper .navbar_logo img {
  width: 17rem;
  height: 17rem;
}

@media (max-width: 800px) {
  .navbarM .navbar_wrapper .navbar_logo img {
    width: 14rem;
    height: 14rem;
  }
}

@media (max-width: 600px) {
  .navbarM .navbar_wrapper .navbar_logo img {
    width: 10rem;
    height: 10rem;
  }
}

.navbarM .navbar_wrapper .nav-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  gap: 1rem;
}

@media (max-width: 400px) {
  .navbarM .navbar_wrapper .nav-content {
    max-width: 25rem;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
  color: #217e37;
}

@media (max-width: 700px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-u {
    gap: 1rem;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper {
  font-size: 2.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper span {
  font-size: 3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

@media (max-width: 850px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper span {
    display: none;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon {
  background-color: #217e37;
  position: relative;
  text-align: center;
  height: 38px;
  width: 38px;
  line-height: 38px;
  border-radius: 50%;
  margin-right: 20px;
  -webkit-animation: pulse2 infinite 2s;
          animation: pulse2 infinite 2s;
}

.navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon svg {
  top: 7px;
  left: 8px;
  color: white;
  position: relative;
  display: block;
  font-size: 2.2rem;
}

@media (max-width: 450px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon svg {
    top: 9px;
    left: 10px;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u .nav-icon-wrapper .nav-icon::after {
  content: "";
  height: 58px;
  width: 58px;
  border-radius: 50%;
  position: absolute;
  top: -9px;
  left: -10px;
  -webkit-animation: pulse2 infinite 2s;
          animation: pulse2 infinite 2s;
}

@-webkit-keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #008f34;
            box-shadow: 0 0 0 0 #008f34;
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}

@keyframes pulse2 {
  0% {
    -webkit-box-shadow: 0 0 0 0 #008f34;
            box-shadow: 0 0 0 0 #008f34;
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u button {
  cursor: pointer;
  background-color: #217e37;
  padding: 1.4rem 3rem;
  border: none;
  color: white;
  font-size: 2.8rem;
  border-radius: 5rem;
}

@media (max-width: 500px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-u button {
    display: none;
    font-size: 2.5rem;
    border-radius: 2rem;
    padding: 1.4rem 2rem;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-u svg {
  font-size: 3rem;
  display: none;
}

@media (max-width: 900px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-u svg {
    display: block;
  }
}

@media (max-width: 900px) {
  .navbarM .navbar_wrapper .nav-content .nav-content-d {
    display: none;
  }
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a {
  text-transform: uppercase;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .sublink {
  position: relative;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list.active {
  -webkit-transform: scaleY(1);
          transform: scaleY(1);
  opacity: 1;
  visibility: visible;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list {
  border-top: 2px solid #217e37;
  background-color: white;
  -webkit-box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
          box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  left: 0;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: absolute;
  top: 100%;
  z-index: 11;
  list-style-type: none;
  visibility: hidden;
  -webkit-transform: scaleY(0);
          transform: scaleY(0);
  -webkit-transform-origin: 0 0 0;
          transform-origin: 0 0 0;
  opacity: 0;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 24rem;
  gap: 0;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a {
  padding: 0.5rem 1rem;
  font-weight: 400;
  border-bottom: solid 1px #e9e9e9;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
  text-transform: capitalize;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover {
  padding-left: 4rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover .icon {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-right: 1rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0;
  font-size: 1.5rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li .icon {
  position: absolute;
  left: 20px;
  -webkit-transform: scale(0);
          transform: scale(0);
  -webkit-transition: all 0.5s ease 0s;
  transition: all 0.5s ease 0s;
  font-size: 1.5rem;
  color: #217e37;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a li .icon.active {
  -webkit-transform: scale(1);
          transform: scale(1);
  margin-right: 1rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a .nav-list a:hover li {
  color: #217e37;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a {
  font-weight: 550;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a li {
  font-size: 1.7rem;
  color: black;
  padding: 1.5rem;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul a li:hover {
  color: #217e37;
}

.navbarM .navbar_wrapper .nav-content .nav-content-d ul .link.active li {
  color: #217e37;
}

.navbarM .MeanNav .mean-nav-list.active {
  max-height: 60rem;
}

.navbarM .MeanNav .mean-nav-list {
  display: none;
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.7s ease-in-out;
  transition: max-height 0.7s ease-in-out;
}

@media (max-width: 900px) {
  .navbarM .MeanNav .mean-nav-list {
    display: block;
  }
}

.navbarM .MeanNav .mean-nav-list .link.active li {
  color: #217e37;
}

.navbarM .MeanNav .mean-nav-list a li {
  padding: 1rem 0rem;
  color: black;
  font-weight: 600;
  border-top: dashed #ddd 1px;
}

.navbarM .MeanNav .mean-nav-list a li .rightIcon {
  color: #217e37;
  padding-right: 1rem;
}

.navbarM .MeanNav .mean-nav-list a .plus {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.navbarM .MeanNav .mean-nav-list a .plus div {
  color: black;
  font-weight: 600;
  font-size: inherit;
}

.navbarM .MeanNav .mean-nav-list a .plus .plusicon {
  font-weight: 600;
  font-size: 2.5rem;
}

.navbarM .MeanNav .mean-nav-list a .sub-mean {
  list-style-type: none;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.7s ease-in-out;
  transition: max-height 0.7s ease-in-out;
}

.navbarM .MeanNav .mean-nav-list a .sub-mean a li {
  padding-left: 5rem;
}

.navbarM .MeanNav .mean-nav-list a .sub-mean.servi {
  max-height: 60rem;
}

.navbarM .MeanNav .mean-nav-list a .sub-mean.why {
  max-height: 60rem;
}

.navbarM.active {
  top: 0;
  opacity: inherit;
  overflow: visible;
}

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  background-color: red;
}

#scroll-text {
  text-align: left;
  font-size: 2.3rem;
  color: yellow;
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
  -webkit-animation: my-animation 20s linear infinite;
          animation: my-animation 20s linear infinite;
}

@media (max-width: 500px) {
  #scroll-text {
    -webkit-animation: my-animation-mob 15s linear infinite;
            animation: my-animation-mob 15s linear infinite;
  }
}

@-webkit-keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(-90%);
  }
}

@keyframes my-animation {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(-90%);
  }
}

@-webkit-keyframes my-animation-mob {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(-180%);
  }
}

@keyframes my-animation-mob {
  from {
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -webkit-transform: translateX(100%);
    transform: translateX(-180%);
  }
}
