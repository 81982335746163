@import "../../common/variables/variables.scss";

.carpet-banner {
  background: url(../../../Assets/Images/Banner/banner-1.bk.jpg);
  background-size: cover;
  background-position: top;
  width: 100%;
  height: 70vh;

  @media (max-width: 800px) {
    background-position: 0% 0%;
  }

  .content-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;

    .content {
      padding: 5rem 13%;

      @media (max-width: 1200px) {
        padding: 5rem 7%;
      }
      @media (max-width: 800px) {
        padding: 5rem 2rem;
      }

      .info {
        animation: jump-shaking 2s ease-out;
        h1 {
          margin-top: 18rem;
          color: #c2db5d;
          margin-bottom: 0.5rem;
        }

        h2 {
          font-size: 4rem;
          color: $main-l;
          margin-bottom: 0.5rem;
        }

        h3 {
          font-size: 4rem;
          color: white;
          background-color: #4a4a4c;
          padding: 0.5rem 1rem;
          width: max-content;

          span {
            font-weight: lighter;
            font-size: 1.2rem;
          }
        }

        span {
          font-size: 2.5rem;
        }
      }
      a {
        margin-top: 2rem;
        display: flex;
        align-items: center;
        color: black;
        border: 1px solid rgb(1, 137, 177);
        border-radius: 4rem;
        width: fit-content;
        animation: jump-shaking1 2s ease-out;

        &:hover {
          animation-name: btn1;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
        }

        span {
          font-size: 2.5rem;
          margin-right: 2rem;
          margin-left: 1rem;
          font-weight: 600;
          // text-shadow: 2px 2px 2px $blue;
        }
        img {
          width: 7rem;
          height: 5rem;
        }
      }
    }
  }
}

@keyframes jump-shaking {
  0% {
    transform: translateX(-20rem);
    opacity: 0.2;
  }
  100% {
    transform: translateX(0rem);
    opacity: 1;
  }
}

@keyframes jump-shaking1 {
  0% {
    transform: translateY(10rem);
    opacity: 0;
  }
  100% {
    transform: translateY(0rem);
    opacity: 1;
  }
}

.sub-banner,
.last-banner {
  img {
    width: 100%;
  }
}

.carpet_service {
  padding: 5rem 13%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .main_info {
    margin-bottom: 6rem;

    h2 {
      text-align: center;

      color: $main;
      font-size: 4rem;
      margin-bottom: 4rem;
    }
    p {
      text-align: justify;
      text-justify: inter-word;
      margin-bottom: 1.8rem;
      margin-top: 1rem;
      line-height: 3rem;
    }

    .info {
      display: flex;
      align-items: center;
      gap: 3rem;
      @media (max-width: 500px) {
        margin-top: 3rem;
        flex-direction: column-reverse;
      }

      .content {
        width: 70%;
        @media (max-width: 500px) {
          width: 100%;
        }
      }
    }

    h3 {
      margin-top: 4rem;
      color: #c2db5d;
    }
    h4 {
      font-size: 3rem;
      color: black;
      margin-top: 1rem;
      font-weight: 400;
    }

    span {
      color: $main;
      text-decoration: underline;
      font-size: 2rem;
      line-height: 3rem;
      cursor: pointer;
    }
  }

  .review_info {
    margin-bottom: 6rem;
    h2 {
      text-align: center;
      color: $main;
      font-size: 4rem;
      margin-bottom: 1.5rem;
    }

    .review_videos {
      margin-top: 5rem;
      grid-gap: 2rem;
      display: grid;
      gap: 6rem;
      grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));

      iframe {
        width: 100%;
      }
    }
  }

  .cleaning_info {
    margin-bottom: 3rem;
    h2 {
      color: $main-l;
      text-align: center;
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
    }
    h3 {
      text-align: center;
      color: black;
      font-size: 4rem;
    }
    .imgs {
      margin-top: 3rem;
      margin-bottom: 3rem;
      grid-gap: 2rem;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));

      .img-content {
        img {
          width: 100%;
          padding: 2rem;
        }
      }
    }

    a {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: #4a4a4c;
      border-bottom: 5px solid $main-l;

      @media (max-width: 650px) {
        flex-direction: column;

        img {
          margin-bottom: 2rem;
        }

        h4 {
          font-size: 3rem;
          text-align: center;
        }
      }
      h4 {
        padding: 4rem 2rem;
        cursor: pointer;
        font-size: 3.7rem;
        text-transform: uppercase;
        color: white;
      }

      img {
        width: 10rem;
        height: 7rem;
        margin-right: 5rem;
      }
    }
  }
}

.solution {
  padding: 8rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  background-color: #eee;
  margin-top: 2rem;
  // margin-bottom: 4rem;
  .solution_heading {
    margin-bottom: 3rem;
    h2 {
      text-align: center;
      color: $main-l;
      font-size: 3.5rem;
      margin-bottom: 1.5rem;
    }
    h3 {
      text-align: center;
      color: black;
      font-size: 4rem;
    }
  }

  .solution_content {
    grid-gap: 2rem;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

    .solution_box {
      background-color: white;
      padding: 2rem;
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      // justify-content: center;

      h2 {
        font-size: 2.3rem;
        margin-bottom: 2rem;
        color: $main-l;
      }

      h3 {
        min-height: 5rem;
        font-size: 2rem;
        color: black;
        margin-bottom: 1rem;
      }

      p {
        line-height: 3rem;

        span {
          color: $main-l;
          font-size: 2rem;
          line-height: 3rem;
          cursor: pointer;
        }
      }

      img {
        width: 20rem;
        border-radius: 50%;
        margin-bottom: 2rem;
      }
    }
  }
}

.protected {
  padding: 8rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .heading {
    text-align: center;
    margin-bottom: 5rem;
    h1 {
      color: $main-l;
      font-size: 3rem;
      margin-bottom: 2rem;
    }
    h2 {
      color: black;
      font-size: 3.5rem;
    }
  }

  .main-content {
    .content-u {
      margin-bottom: 5rem;
      display: flex;
      align-items: center;
      gap: 5rem;
      @media (max-width: 750px) {
        flex-direction: column;
      }

      .info {
        width: 50%;
        @media (max-width: 750px) {
          width: 100%;
        }
        p {
          margin-bottom: 2rem;
          line-height: 3rem;
        }

        h1 {
          line-height: 3.5rem;
          color: #6aa121;
          font-size: 3rem;
          margin-bottom: 2rem;
        }
      }

      .content {
        width: 50%;
        @media (max-width: 750px) {
          width: 100%;
        }
        iframe {
          width: 100%;
          min-height: 20rem;
        }
      }
    }

    .content-d {
      display: flex;
      align-items: center;
      gap: 5rem;

      @media (max-width: 750px) {
        flex-direction: column-reverse;
      }

      .content {
        width: 50%;

        @media (max-width: 750px) {
          width: 100%;
        }
        img {
          width: 100%;
          padding: 2rem;
        }
      }

      .info {
        width: 50%;
        @media (max-width: 750px) {
          width: 100%;
        }
        h1 {
          color: $main-l;
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        h2 {
          color: black;
          font-size: 3rem;
          margin-bottom: 1rem;
        }

        p {
          margin-bottom: 2rem;
          line-height: 3rem;
        }
      }
    }
  }
}

.experience {
  padding: 8rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  background: url(../../../Assets/Images/Banner/Capture.PNG);
  background-repeat: no-repeat;
  background-size: cover;

  .content-u {
    max-width: 90rem;
    margin-bottom: 15rem;
    h1 {
      font-size: 3.5rem;
      font-weight: 700;
      color: white;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 4rem;
      font-weight: 400;
      color: white;
    }

    @media (max-width: 750px) {
      text-align: center;
      margin-bottom: 4rem;

      h1 {
        font-size: 3rem;
      }

      h2 {
        font-size: 3.5rem;
      }
    }
  }

  .content-d {
    max-width: 90rem;
    display: flex;
    align-items: center;
    gap: 1rem;

    @media (max-width: 750px) {
      flex-direction: column;
      text-align: center;
    }

    .img {
      img {
        width: 16rem;
      }
    }

    .info {
      h1 {
        color: black;
        font-size: 3rem;
        margin-bottom: 1rem;
      }

      h2 {
        color: white;
        font-size: 3.5rem;
        margin-bottom: 1rem;
      }

      p {
        color: white;
        font-size: 2.7rem;
        margin-bottom: 1rem;
      }
    }
  }
}

.last-banner {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 5rem;
  img {
    max-width: 80rem;
  }
}

.benefits {
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  width: 100%;
  background-image: url(../../../Assets/Images/Banner/benifits-of-cleaning.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;

  .benefits-wrapper {
    padding-left: 40%;

    @media (max-width: 1200px) {
      padding-left: 0;
    }
    .benefits-heading {
      margin-bottom: 4rem;
      h2 {
        text-transform: uppercase;
        color: $main;
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      h1 {
        text-transform: uppercase;
        color: black;
        font-size: 3rem;
      }
    }

    .benefits-content {
      grid-gap: 3rem;
      display: grid;
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

      @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      }

      .benefit-box {
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        .benefit-img {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          padding: 1.5rem;
          min-height: 8rem;
          min-width: 8rem;
          border-radius: 50%;
          box-shadow: 0px 0px 8px 4px rgba(3, 3, 3, 0.2);
          transition: all 0.5s ease;

          &:hover {
            transform: scale(1.2);
          }
          img {
            width: 4rem;
            //   width: 8rem;
          }
        }

        .benefit-info {
          h4 {
            color: $main-l;
            font-size: 2rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
