h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.footer-1 {
  background-color: #008f34;
  grid-gap: 2rem;
  display: -ms-grid;
  display: grid;
  gap: 2rem;
  -ms-grid-columns: (minmax(30rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  padding: 0rem 15% 10rem 15%;
}

@media (max-width: 1200px) {
  .footer-1 {
    padding: 0rem 5% 15rem 5%;
  }
}

@media (max-width: 800px) {
  .footer-1 {
    padding: 0rem 3rem 15rem 3rem;
  }
}

.footer-1 .links,
.footer-1 .newsletter,
.footer-1 .service-off,
.footer-1 .contact-info {
  padding-top: 5rem;
}

.footer-1 .newsletter .news-content p {
  color: white;
  margin-bottom: 3rem;
}

.footer-1 .newsletter .news-content input {
  height: 5rem;
  background-color: #008f34;
  border: none;
  color: white;
  font-size: 2rem;
  border: solid 1px white;
  padding-left: 2rem;
  border-radius: 5px;
  margin-bottom: 3rem;
}

.footer-1 .newsletter .news-content input::-webkit-input-placeholder {
  color: white;
}

.footer-1 .newsletter .news-content input:-ms-input-placeholder {
  color: white;
}

.footer-1 .newsletter .news-content input::-ms-input-placeholder {
  color: white;
}

.footer-1 .newsletter .news-content input::placeholder {
  color: white;
}

.footer-1 .newsletter .news-content a {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border: 1px solid white;
  border-radius: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.footer-1 .newsletter .news-content a:hover {
  -webkit-animation-name: btn1;
          animation-name: btn1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.footer-1 .newsletter .news-content a span {
  font-size: 2.2rem;
  margin-right: 2rem;
  margin-left: 1rem;
  color: white;
}

.footer-1 .newsletter .news-content a img {
  width: 7rem;
  height: 5rem;
}

.footer-1 .contact-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background-color: #c2db5d;
  gap: 2rem;
  position: relative;
}

.footer-1 .contact-info .info-box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.footer-1 .contact-info .info-box img {
  width: 8rem;
  height: 7.2rem;
  margin-bottom: 1rem;
}

.footer-1 .contact-info .info-box span {
  font-size: 2rem;
  font-weight: 600;
}

.footer-1 .contact-info::after {
  content: "";
  width: 100%;
  height: 61px;
  background: url(../../../Assets/Images/Icons/footer-extra.png) no-repeat 0 0;
  background-size: 100% 100%;
  position: absolute;
  left: 0;
  top: 100%;
}

.footer-1 .heading {
  margin-bottom: 2rem;
}

.footer-1 .heading h1 {
  color: white;
  font-size: 2.5rem;
  position: relative;
}

.footer-1 .heading h1::after {
  content: "";
  height: 3px;
  width: 190px;
  background: #c2db5d;
  position: absolute;
  left: 0;
  top: 100%;
}

.footer-1 .content ul {
  list-style-type: none;
}

.footer-1 .content ul li {
  margin-bottom: 1rem;
}

.footer-1 .content ul li a {
  color: white;
}

.footer-1 .content ul li a svg {
  margin-right: 5px;
}

.footer-2 {
  padding: 0rem 8rem;
}

@media (max-width: 1200px) {
  .footer-2 {
    padding: 0rem 5rem;
  }
}

@media (max-width: 800px) {
  .footer-2 {
    padding: 0rem 3rem;
  }
}

.footer-2 p {
  padding: 3rem 0;
  color: black;
}

.enquire-fix-wrapper {
  position: fixed;
  z-index: 100;
  top: 50%;
  -webkit-transform: translateY(-50%) rotate(90deg);
          transform: translateY(-50%) rotate(90deg);
  right: -55px;
}

@media (max-width: 1050px) {
  .enquire-fix-wrapper {
    right: -50px;
  }
}

.enquire-fix-wrapper .enquire-fix {
  cursor: pointer;
  background-color: #045ad2;
  font-size: 2.2rem;
  border-radius: 5px;
  border: none;
  color: #ffffff;
  -webkit-animation: blueglow infinite 2s;
          animation: blueglow infinite 2s;
}

@-webkit-keyframes blueglow {
  0% {
    -webkit-box-shadow: 0 0 0 0 #045ad2;
            box-shadow: 0 0 0 0 #045ad2;
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}

@keyframes blueglow {
  0% {
    -webkit-box-shadow: 0 0 0 0 #045ad2;
            box-shadow: 0 0 0 0 #045ad2;
  }
  40% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    -webkit-box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
            box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}

@-webkit-keyframes glowing {
  0% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 3px #045ad2;
            box-shadow: 0 0 3px #045ad2;
  }
  50% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 40px #045ad2;
            box-shadow: 0 0 40px #045ad2;
  }
  100% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 3px #045ad2;
            box-shadow: 0 0 3px #045ad2;
  }
}

@keyframes glowing {
  0% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 3px #045ad2;
            box-shadow: 0 0 3px #045ad2;
  }
  50% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 40px #045ad2;
            box-shadow: 0 0 40px #045ad2;
  }
  100% {
    background-color: #045ad2;
    -webkit-box-shadow: 0 0 3px #045ad2;
            box-shadow: 0 0 3px #045ad2;
  }
}
