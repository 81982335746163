h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.pressure-banner {
  height: 80vh;
  background-size: cover;
  background-position: center;
}

.pressure-banner .container {
  padding-top: 30rem;
  padding-left: 10rem;
}

@media (max-width: 800px) {
  .pressure-banner .container {
    padding-left: 3rem;
    padding-top: 27rem;
  }
}

.pressure-banner .container h1 {
  font-size: 4.5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
}

@media (max-width: 800px) {
  .pressure-banner .container h1 {
    font-size: 3.2rem;
  }
}

.pressure-banner .container h3 {
  margin-top: 1rem;
  font-size: 3.5rem;
  color: white;
  text-shadow: 3px 3px 1px black;
  margin-bottom: 2rem;
}

@media (max-width: 800px) {
  .pressure-banner .container h3 {
    font-size: 2.5rem;
  }
}

.pressure-banner .container .rug-contact {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  border: 1px solid #0189b1;
  border-radius: 4rem;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.pressure-banner .container .rug-contact:hover {
  -webkit-animation-name: btn1;
          animation-name: btn1;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-timing-function: ease-in-out;
          animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
}

.pressure-banner .container .rug-contact span {
  font-size: 2.5rem;
  margin-right: 2rem;
  margin-left: 1rem;
  font-weight: 600;
  text-shadow: 2px 2px 2px black;
}

.pressure-banner .container .rug-contact img {
  width: 7rem;
  height: 5rem;
}

.pressure {
  margin-top: 5rem;
}

.pressure .pressure-content {
  padding: 5rem 13%;
  margin-bottom: -8rem;
}

@media (max-width: 1200px) {
  .pressure .pressure-content {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .pressure .pressure-content {
    padding: 5rem 2rem;
  }
}

.pressure .pressure-content .main-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 5rem;
}

.pressure .pressure-content .main-content .heading {
  width: 48%;
}

@media (max-width: 800px) {
  .pressure .pressure-content .main-content .heading {
    width: 100%;
  }
}

.pressure .pressure-content .main-content .heading h2 {
  font-size: 3.5rem;
  color: #008f34;
  margin-bottom: 0.5rem;
}

.pressure .pressure-content .main-content .heading h3 {
  color: black;
  margin-bottom: 0.5rem;
}

.pressure .pressure-content .main-content .heading p {
  margin-top: 1rem;
  text-align: justify;
}

.pressure .pressure-content .main-content .heading .buttons {
  margin-top: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 2rem;
}

.pressure .pressure-content .main-content .heading .buttons a {
  font-size: 2.5rem;
  background-color: #008f34;
  padding: 0.8rem 1.3rem;
  color: white;
  cursor: pointer;
  -webkit-transition: all ease 0.5s;
  transition: all ease 0.5s;
}

.pressure .pressure-content .main-content .heading .buttons a:hover {
  background-color: greenyellow;
}

.pressure .pressure-content .main-content .img {
  width: 48%;
}

@media (max-width: 800px) {
  .pressure .pressure-content .main-content .img {
    width: 100%;
  }
}
