h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.specials {
  padding: 5rem 13%;
}

@media (max-width: 1200px) {
  .specials {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .specials {
    padding: 5rem 2rem;
  }
}

.specials .heading {
  text-align: center;
  margin-bottom: 3rem;
}

.specials .heading h2 {
  color: #008f34;
  font-size: 4rem;
}

.specials .heading h2 span {
  font-size: inherit;
  color: #000;
}

.specials .content {
  grid-gap: 2rem;
  display: -ms-grid;
  display: grid;
  gap: 2rem;
  -ms-grid-columns: (minmax(38rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(38rem, 1fr));
}

.specials .content .img-container {
  -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.7);
          box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.7);
  -webkit-animation-name: fade-in;
          animation-name: fade-in;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

.specials .content .img-container a {
  cursor: pointer;
}

.specials .content .img-container a img {
  width: 100%;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
            transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
