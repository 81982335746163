@import "../../common/variables/variables.scss";

.home {
  width: 100%;
  height: 60rem;
  background-image: linear-gradient(90deg, rgba(71, 71, 71, 0.75), transparent),
    url(../../../Assets/Images/Banner/home-banner.jpg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  @media (max-width: 500px) {
    background-image: linear-gradient(
        90deg,
        rgba(71, 71, 71, 0.75),
        transparent
      ),
      url(../../../Assets/Images/Banner/home-banner-mob-min.jpg);
  }
  .main_banner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 15%;
    padding-top: 15rem;

    @media (max-width: 800px) {
      padding-left: 2rem;
    }

    .main_banner_content {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      button {
        align-self: flex-start;
        font-size: 1.9rem;
        border: none;
        cursor: pointer;
        outline: none;
        background-color: black;
        color: $orange-l;
      }

      h1 {
        font-size: 5rem;
        color: white;
        text-shadow: 3px 3px 1px black;
        @media (max-width: 500px) {
          font-size: 4rem;
        }
      }

      h2 {
        color: white;
        font-size: 2.5rem;
        text-shadow: 3px 3px 1px black;
        @media (max-width: 500px) {
          display: none;
        }
      }
      h3 {
        display: none;
        font-size: 2.5rem;
        color: white;
        text-shadow: 3px 3px 1px black;

        span {
          font-size: inherit;
          color: $blue;
        }

        @media (max-width: 500px) {
          display: block;
        }
      }
      p {
        font-size: 3.5rem;
        text-shadow: 2px 2px 1px black;
        color: white;
      }

      p {
        font-size: 3rem;
      }

      // a {
      //   display: flex;
      //   align-items: center;
      //   color: white;
      //   border: 1px solid rgb(1, 137, 177);
      //   border-radius: 4rem;
      //   width: fit-content;

      //   &:hover {
      //     animation-name: btn1;
      //     animation-duration: 1s;
      //     animation-timing-function: ease-in-out;
      //     animation-iteration-count: 1;
      //   }

      //   span {
      //     font-size: 2.5rem;
      //     margin-right: 2rem;
      //     margin-left: 1rem;
      //     font-weight: 600;
      //     text-shadow: 2px 2px 2px black;
      //   }
      //   img {
      //     width: 7rem;
      //     height: 5rem;
      //   }
      // }
    }

    @keyframes jump-shaking {
      0% {
        transform: translateX(-20rem);
        opacity: 0.2;
      }
      100% {
        transform: translateX(0rem);
        opacity: 1;
      }
    }
  }
}

.banner-strip {
  // position: relative;
  // top: -30px;

  margin: -30px 10%;

  @media (max-width: 1200px) {
    margin: -30px 7%;
  }
  @media (max-width: 800px) {
    margin: -30px 2rem;
    display: none;
  }
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background-color: $main-l;
  color: white;
  text-align: center;

  h2 {
    @media (max-width: 750px) {
      font-size: 1.7rem;
    }

    span {
      font-size: inherit;
      text-decoration: underline;
    }
  }
}

.pick_up.section {
  .pick_up.heading {
    text-align: center;
    margin-bottom: 4rem;
    padding: 2rem 1rem;

    h2 {
      color: $main;
      font-size: 4rem;
    }
  }

  .pick_up.content {
    display: flex;
    align-items: center;
    gap: 5rem;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    .pick_up.info {
      width: 125%;

      @media (max-width: 750px) {
        width: 100%;
      }
      h5 {
        color: black;
        font-size: 3.5rem;

        margin-bottom: 1rem;
      }

      p {
        font-size: 2rem;
        line-height: 3.2rem;
        margin-bottom: 1rem;
      }
    }

    .pick_up.img {
      img {
        width: 100%;
        border-radius: 0.5rem;
        @media (max-width: 750px) {
          width: 38rem;
          height: 40rem;
        }
      }
    }
  }
}

.lease-banner {
  padding: 8rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  background: url(../../../Assets/Images/Banner/3d-banner.webp);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;

  .wrapper {
    display: flex;
    gap: 3rem;
    align-items: center;
    @media (max-width: 600px) {
      flex-direction: column;
    }

    .img {
      img {
        width: 100%;
      }
    }

    .content {
      max-width: 55rem;
      h3 {
        font-size: 4rem;
        margin-bottom: 0.5rem;
      }
      h2 {
        color: white;
        margin-bottom: 0.5rem;
      }

      p {
        color: white;
        font-size: 2.5rem;
      }

      @media (max-width: 700px) {
        max-width: fit-content;
      }
    }
  }
}

.wet-page {
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .heading {
    margin-bottom: 2rem;
    h2 {
      font-size: 4rem;
    }
  }

  .content {
    display: flex;
    gap: 4rem;
    @media (max-width: 750px) {
      align-items: center;
      flex-direction: column;
      gap: 2rem;
    }

    .img {
      width: 40%;
      img {
        width: 100%;
      }
      @media (max-width: 750px) {
        width: 100%;
      }
    }

    .info {
      width: 60%;
      @media (max-width: 750px) {
        width: 100%;
      }
      img {
        width: 100%;
      }
      p {
        margin-bottom: 1rem;
      }
    }
  }
}

.methods {
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  background-color: #eee;
  .methods-heading {
    h2 {
      text-align: center;
      color: $main-l;
      padding: 0.5rem 0rem;
      margin-bottom: 3rem;
      font-size: 3.5rem;
    }
  }

  .methods-content {
    .methods-info {
      grid-gap: 2rem;
      display: grid;
      gap: 2rem;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
      .methods-type {
        background-color: white;
        display: flex;
        flex-direction: column;
        min-width: 30rem;

        .methods-type-heading {
          padding-top: 5rem;
          h2 {
            font-size: 2.5rem;
            text-transform: capitalize;
            color: $main-l;
            text-align: center;
          }
        }

        .methods-type-img {
          padding: 4rem;
          img {
            cursor: pointer;
            border-radius: 0.5rem;
            width: 100%;
            transition: 0.2s linear;
            max-height: 36rem;
            @media (max-width: 800px) {
              height: 40rem;
            }

            &:hover {
              transform: scale(1.1);
            }
          }
        }
        .methods-type-info {
          text-align: justify;
          span {
            color: $main-l;
            font-size: 2rem;
            line-height: 3rem;
            cursor: pointer;
          }

          cursor: pointer;
          padding: 0rem 2rem 2rem 2rem;
          font-size: 2rem;
          line-height: 3rem;
          color: #686868;

          a {
            span {
              font-size: 2rem;
              line-height: 3rem;
              color: $main-l;
            }
          }
        }
      }
    }
  }
}

.types.section {
  .types.heading {
    margin-bottom: 2rem;
  }
  .types.content {
    display: flex;
    gap: 5rem;
    align-items: flex-start;

    @media (max-width: 750px) {
      align-items: center;
      flex-direction: column;
    }

    .types.info {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
      width: 50%;

      h2 {
        margin-bottom: 1rem;
        text-align: center;
        font-size: 3.5rem;
      }

      p {
        font-size: 2rem;
        line-height: 3.5rem;
      }
      img {
        max-width: 50rem;
        @media (max-width: 750px) {
          width: 40rem;
          height: 30rem;
        }
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }

    .types.img {
      width: 50%;
      .tube {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        @media (max-width: 900px) {
          display: flex;
        }

        iframe {
          width: 45rem;
          height: 35rem;

          @media (max-width: 600px) {
            width: 40rem;
            height: 35rem;
          }
        }
        h2 {
          text-align: center;
          color: black;
          font-size: 3.5rem;
          margin-bottom: 3rem;
        }
      }

      @media (max-width: 750px) {
        width: 100%;
      }
    }
  }
}

.review.section {
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
}

.choose_us.section {
  .choose_us.heading {
    margin-bottom: 4rem;

    h2 {
      text-align: center;
      padding: 2rem 1rem;
      font-size: 3rem;
    }
  }

  .choose_us.content {
    display: flex;
    align-items: center;

    gap: 5rem;

    @media (max-width: 750px) {
      flex-direction: column;
    }
    .choose_us.info {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

      ul {
        list-style: none;

        li {
          text-align: start;
          display: flex;
          align-items: center;
          color: #948e8e;
          font-weight: 600;
          font-size: 2.8rem;
          margin-bottom: 2.5rem;

          @media (max-width: 500px) {
            font-size: 2rem;
          }

          img {
            width: 5rem;
            color: $main-l;
            margin-right: 1rem;
            @media (max-width: 800px) {
              height: 5rem;
            }
          }

          svg {
            font-size: 3rem;
            color: $main-l;
            margin-right: 1rem;
          }
        }
      }
    }
    .choose_us.img {
      img {
        width: 100%;
      }
    }
  }
}

.extra {
  .extra1 {
    background-color: #eee;
    padding: 5rem 20%;

    @media (max-width: 1200px) {
      padding: 5rem 15%;
    }
    @media (max-width: 800px) {
      padding: 5rem 2rem;
    }
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
      max-width: 15rem;
      @media (max-width: 800px) {
        height: 15rem;
      }
    }

    h2 {
      text-align: center;
      margin-bottom: 2rem;
      font-size: 3rem;
      color: #000;
    }
    p {
      text-align: center;
    }
  }

  .extra2 {
    background: url(../../../Assets/Images/Banner/pet-banner.webp);
    .heading {
      margin-bottom: 3rem;
      h1 {
        color: #000;
      }
    }

    .extra2-wrapper {
      display: flex;
      align-items: center;
      gap: 2rem;

      @media (max-width: 700px) {
        justify-content: center;
        flex-direction: column-reverse;
      }

      .extra2-img {
        display: flex;
        justify-content: center;
        width: 170%;
        padding: 1rem;

        @media (max-width: 700px) {
          width: 100%;
        }

        img {
          max-width: 40rem;
          @media (max-width: 750px) {
            width: 40rem;
            height: 37rem;
          }
        }
      }

      .extra2-info {
        p {
          font-size: 2.2rem;
          line-height: 4rem;
          strong {
            font-size: inherit;
          }
        }
      }
    }
  }
}

.work.section {
  .work.heading {
    h2 {
      text-align: center;
      color: $main-l;
      font-size: 3.5rem;
    }
  }
}

.frequency-wrapper {
  .frequency {
    background-image: url(../../../Assets/Images/Banner/map-banner.webp);
    padding: 5rem 15%;

    @media (max-width: 1200px) {
      padding: 5rem 7%;
    }
    @media (max-width: 800px) {
      padding: 5rem 2rem;
    }
    text-align: center;
    h1 {
      color: #000;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 4rem;
    }

    .frequency-content {
      .frequency-box {
        img {
          width: 100%;
          margin-bottom: 4rem;
          @media (max-width: 800px) {
            height: 15rem;
          }
          @media (max-width: 650px) {
            height: 13rem;
          }
          @media (max-width: 500px) {
            height: 12rem;
          }
        }

        h2 {
          color: $main-l;
        }
      }
    }
  }
}

.protect.section {
  padding: 5rem 13%;
  background-image: url(../../../Assets/Images/Banner/pet-banner.webp);

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .protect.content {
    display: flex;
    align-items: center;
    gap: 5rem;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    .protect.info {
      width: 90%;

      @media (max-width: 750px) {
        width: 100%;
      }

      h2 {
        margin-bottom: 1rem;
        color: $main-l;
        font-size: 4rem;
      }

      h3 {
        margin-bottom: 2rem;
        color: black;
        font-size: 3rem;
      }

      p {
        font-size: 2rem;
        line-height: 3.5rem;
      }
    }

    .protect.img {
      img {
        width: 100%;
        border-radius: 0.5rem;
        @media (max-width: 750px) {
          width: 50rem;
          height: 30rem;
        }
        border-radius: 0.5rem;
        @media (max-width: 450px) {
          width: 40rem;
          height: 25rem;
        }
      }
    }
  }
}

.copyright.section {
  background-color: #eee;
}

.fixed-icon {
  display: flex;
  flex-direction: column;
  position: fixed;
  right: 30px;
  bottom: 30px;
  z-index: 200;
}

@media (max-width: 600px) {
  .fixed-icon {
    right: 10px;
    bottom: 10px;
  }
}
.Call-now-button {
  background-color: none;
  background-color: #045ad2;
  margin-top: 15px;
  border-radius: 5px;
  z-index: 10;
}

.Call-now-button .phone-icon1 {
  font-size: 3.4rem;
  margin: 10px;
  cursor: pointer;
  color: white;
  position: relative;
  z-index: 10;
}

.section {
  padding: 5rem 13%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
}

.fixed-top {
  position: fixed;
  background-color: $main-l;
  bottom: 2%;
  right: 2%;
  padding: 1.5rem;
  border-radius: 50%;
  opacity: 0;
  transition: all 0.5s ease;
  cursor: pointer;
  svg {
    color: white;
    width: 2rem;
    height: 2rem;
  }

  &.active {
    opacity: 1;
  }
}

.whatsapp-button {
  position: fixed;
  bottom: 7%;
  padding: 0.5rem 0.7rem;
  background-color: #25d365;
  border-radius: 5px;
  z-index: 10;
  left: 1%;
  @media (max-width: 800px) {
    left: 10%;
  }

  svg {
    font-size: 4rem;
    color: white;
  }
}

.Appointment-fixed {
  display: none;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 1%;
  z-index: 10;
  a {
    padding: 0.7rem 0;
    width: 100%;
    border-radius: 5px;
    text-align: center;
    background-color: #045ad2;
    margin: 0rem 5%;
    font-size: 2.5rem;
    color: white;
  }
  @media (max-width: 700px) {
    display: flex;
  }
}
