@import "../../common/variables/variables.scss";

.footer-1 {
  background-color: #008f34;
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

  padding: 0rem 15% 10rem 15%;
  @media (max-width: 1200px) {
    padding: 0rem 5% 15rem 5%;
  }
  @media (max-width: 800px) {
    padding: 0rem 3rem 15rem 3rem;
  }

  .links,
  .newsletter,
  .service-off,
  .contact-info {
    padding-top: 5rem;
  }

  .newsletter {
    .news-content {
      p {
        color: white;
        margin-bottom: 3rem;
      }

      input {
        height: 5rem;
        background-color: #008f34;
        border: none;
        color: white;
        font-size: 2rem;
        border: solid 1px white;
        padding-left: 2rem;
        border-radius: 5px;
        margin-bottom: 3rem;

        &::placeholder {
          color: white;
        }
      }

      a {
        display: flex;
        align-items: center;
        border: 1px solid white;
        border-radius: 4rem;
        width: fit-content;

        &:hover {
          animation-name: btn1;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
        }

        span {
          font-size: 2.2rem;
          margin-right: 2rem;
          margin-left: 1rem;
          color: white;
        }
        img {
          width: 7rem;
          height: 5rem;
        }
      }
    }
  }

  .contact-info {
    display: flex;
    flex-direction: column;
    background-color: #c2db5d;
    gap: 2rem;
    position: relative;

    .info-box {
      display: flex;
      flex-direction: column;
      align-items: center;

      img {
        width: 8rem;
        height: 7.2rem;
        margin-bottom: 1rem;
      }

      span {
        font-size: 2rem;
        font-weight: 600;
      }
    }

    &::after {
      content: "";
      width: 100%;
      height: 61px;
      background: url(../../../Assets/Images/Icons/footer-extra.png) no-repeat 0
        0;
      background-size: 100% 100%;
      position: absolute;
      left: 0;
      top: 100%;
    }
  }

  .heading {
    margin-bottom: 2rem;
    h1 {
      color: white;
      font-size: 2.5rem;
      position: relative;
      &::after {
        content: "";
        height: 3px;
        width: 190px;
        background: #c2db5d;
        position: absolute;
        left: 0;
        top: 100%;
      }
    }
  }

  .content {
    ul {
      list-style-type: none;

      li {
        margin-bottom: 1rem;
        a {
          color: white;
          svg {
            margin-right: 5px;
          }
        }
      }
    }
  }
}

.footer-2 {
  padding: 0rem 8rem;
  @media (max-width: 1200px) {
    padding: 0rem 5rem;
  }
  @media (max-width: 800px) {
    padding: 0rem 3rem;
  }

  p {
    padding: 3rem 0;
    color: black;
  }
}

.enquire-fix-wrapper {
  position: fixed;
  z-index: 100;
  top: 50%;
  transform: translateY(-50%) rotate(90deg);
  right: -55px;

  @media (max-width: 1050px) {
    right: -50px;
  }

  .enquire-fix {
    cursor: pointer;
    background-color: #045ad2;
    font-size: 2.2rem;
    border-radius: 5px;
    border: none;
    color: #ffffff;
    animation: blueglow infinite 2s;
  }
}

@keyframes blueglow {
  0% {
    box-shadow: 0 0 0 0 #045ad2;
  }

  40% {
    box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
  }
}

@keyframes glowing {
  0% {
    background-color: #045ad2;
    box-shadow: 0 0 3px #045ad2;
  }
  50% {
    background-color: #045ad2;
    box-shadow: 0 0 40px #045ad2;
  }
  100% {
    background-color: #045ad2;
    box-shadow: 0 0 3px #045ad2;
  }
}
