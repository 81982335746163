@import "../../../common/variables/variables.scss";

.carpet-Landing-banner {
  height: 80vh;
  background-image: url(../DeepCleaning/dry1.jpg);
  background-size: cover;
  @media (max-width: 1200px) {
    background-position: 70% 0%;
  }
}

.carpet-new-banner {
  height: 80vh;
  background-image: url(../../../../Assets/Images/Banner/carpet-new-banner.jpg);
  // background-image: url(../../../../Assets/Images/Banner/carpet-new-banner.jpg);
  background-size: cover;

  @media (max-width: 1200px) {
    background-position: 70% 0%;
  }

  .content {
    padding-top: 30rem;
    padding-left: 10%;
    h1 {
      color: #c2db5d;
      font-size: 5rem;
      text-shadow: 3px 3px 1px black;
      @media (max-width: 500px) {
        font-size: 4rem;
      }
    }
    @media (max-width: 800px) {
      padding-left: 2rem;
    }
  }
}

.new-comment {
  background-color: $main-l;
  padding: 1rem 0;
  margin-bottom: 6rem;
  h2 {
    text-align: center;
    font-size: 3.5rem;
    color: white;
    @media (max-width: 700px) {
      font-size: 2.8rem;
    }
  }
}

.carpet-land-content {
  padding: 5rem 13%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .main-content {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    margin-bottom: 5rem;
    .heading {
      width: 48%;
      @media (max-width: 800px) {
        width: 100%;
      }
      h2 {
        font-size: 3.5rem;
        color: $main-l;
        margin-bottom: 0.5rem;
      }
      h3 {
        color: black;
        margin-bottom: 0.5rem;
      }
      p {
        margin-top: 1rem;
        text-align: justify;
      }

      .buttons {
        margin-top: 2rem;
        display: flex;
        gap: 2rem;
        // justify-content: center;
        a {
          font-size: 2.5rem;
          background-color: $main-l;
          padding: 0.8rem 1.3rem;
          color: white;
          cursor: pointer;
        }
      }
    }

    .img {
      width: 48%;
      img {
        width: 100%;
      }
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }

  .dry-steam-container {
    margin-top: 2rem;
    .DS-heading {
      h3 {
        text-align: center;
        color: black;
        font-size: 3rem;
      }
    }
    .dry-steam {
      display: flex;
      align-items: center;
      gap: 4rem;
      margin-top: 2rem;
      @media (max-width: 700px) {
        flex-direction: column;
      }
      .dry {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 2rem;
        width: 49%;
        @media (max-width: 700px) {
          width: 100%;
        }
        h4 {
          font-size: 2.5rem;
          text-align: center;
        }
        img {
          max-width: 45rem;
          max-height: 30rem;
        }
      }
    }
  }
}

.sub-banner {
  h2 {
    color: $main-l;
    text-align: center;
    font-size: 4rem;
    margin: 2rem 0;
  }
}
