h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.blog-page-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 3rem;
  padding: 5rem 8%;
}

@media (max-width: 1200px) {
  .blog-page-wrapper {
    padding: 5rem 3%;
  }
}

@media (max-width: 800px) {
  .blog-page-wrapper {
    padding: 5rem 2rem;
  }
}

.blog-page-wrapper .blog-page {
  width: 170%;
}

.blog-page-wrapper .blog-page .blog-page-img {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin-bottom: 4rem;
}

.blog-page-wrapper .blog-page .blog-page-img img {
  padding: 0 7rem;
  max-width: 100%;
  border-radius: 0.5rem;
}

@media (max-width: 750px) {
  .blog-page-wrapper .blog-page .blog-page-img img {
    padding: 0;
  }
}

.blog-page-wrapper .blog-page .blog-page-img h2 {
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 3rem;
  text-transform: capitalize;
}

@media (max-width: 750px) {
  .blog-page-wrapper .blog-page .blog-page-img h2 {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center;
  }
}

.blog-page-wrapper .blog-page .blog-page-info p {
  margin-bottom: 3rem;
  line-height: 3rem;
}

.blog-page-wrapper .blog-page .blog-page-info p span {
  color: #008f34;
  font-size: inherit;
  cursor: pointer;
}

.blog-page-wrapper .blog-page .blog-page-info p strong {
  font-size: inherit;
  color: black;
}

.blog-page-wrapper .blog-page .blog-page-info h3 {
  color: black;
  margin-bottom: 2rem;
}

.blog-page-wrapper .blog-page .blog-page-info ul {
  list-style-type: square;
  margin-left: 2rem;
  margin-bottom: 3rem;
}

.blog-page-wrapper .blog-page .blog-page-info ul li {
  margin-bottom: 1rem;
  line-height: 3rem;
}

@media (max-width: 750px) {
  .blog-page-wrapper .recent-posts {
    display: none;
  }
}

.blog-page-wrapper .recent-posts .heading {
  margin-bottom: 3rem;
}

.blog-page-wrapper .recent-posts .heading h2 {
  font-size: 4rem;
  text-transform: uppercase;
}

.blog-page-wrapper .recent-posts .recent-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 3rem;
}

.blog-page-wrapper .recent-posts .recent-wrapper .recent-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
}

.blog-page-wrapper .recent-posts .recent-wrapper .recent-content .img {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.blog-page-wrapper .recent-posts .recent-wrapper .recent-content .img img {
  border-radius: 50%;
  width: 10rem;
  height: 10rem;
}

.blog-page-wrapper .recent-posts .recent-wrapper .recent-content .info h4 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.blog-page-wrapper .recent-posts .recent-wrapper .recent-content .info span {
  color: #008f34;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
  font-size: 1.8rem;
}
