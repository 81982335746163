@import "../variables/variables.scss";

.banner {
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 47rem;
  display: flex;
  align-items: center;
  justify-content: center;

  .banner-content {
    margin-top: 18rem;

    @media (max-width: 600px) {
      margin-top: 12rem;
    }

    .banner-title {
      text-align: center;
      h1 {
        color: white;
        text-shadow: 2px 2px 1px black;
        text-transform: uppercase;
        font-size: 4rem;
        margin-bottom: 1rem;
      }

      p {
        color: #eee;
        font-size: 2.5rem;
      }

      .buttons {
        display: flex;
        gap: 3rem;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        @media (max-width: 600px) {
          flex-direction: column;
        }

        button {
          color: white;
          display: flex;
          align-items: center;
          gap: 1rem;
          font-size: 2rem;
          border: none;
          font-weight: 900;
          border-radius: 50px;
          padding: 1.5rem 2rem;
          cursor: pointer;
          background-color: $main-l;
          text-transform: uppercase;
          transition: all 0.2s ease-in;

          svg {
            font-size: 2rem;
            color: white;
          }

          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
.breadcrumbs {
  padding-top: 5rem;
  padding-left: 10%;
  @media (max-width: 600px) {
    padding-left: 5%;
  }
  .subs {
    display: flex;
    align-items: center;
    a {
      p {
        text-align: start;
        padding-bottom: 5px;
        margin-right: 1rem;
        font-size: 2rem;
        color: #000;
        text-transform: capitalize;
      }
      .activeC {
        color: rgb(185, 185, 185);
      }
    }
    svg {
      font-size: 1.5rem;
      color: black;
      margin-right: 1rem;
    }
  }
}

.banner-new {
  background: no-repeat scroll center center / cover;
  width: 100%;
  height: 40rem;
}
