h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.fquote {
  padding: 5rem 15%;
}

@media (max-width: 600px) {
  .fquote {
    font-size: 100%;
  }
}

@media (max-width: 1200px) {
  .fquote {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .fquote {
    padding: 5rem 2rem;
  }
}

.fquote .heading {
  margin-bottom: 2rem;
}

.fquote .heading h1 {
  color: #008f34;
}

.fquote .info .info-head {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.fquote .info .info-head .head {
  font-size: 3.2rem;
  cursor: pointer;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 1rem 3rem;
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.fquote .info .info-head .head.active {
  background-color: #eee;
}

.fquote .info .info-content {
  background-color: #eee;
  padding: 1rem;
}

.fquote .info .info-content .main-heading {
  font-size: 2.7rem;
  font-weight: 400;
  color: black;
}

.fquote .info .info-content .size {
  margin-bottom: 1rem;
}

.fquote .info .info-content .size .size-content {
  margin: 2rem 0;
  max-width: 40rem;
}

.fquote .info .info-content .size .size-content input {
  font-size: 2rem;
  width: 100%;
  padding: 1rem;
}

.fquote .info .info-content .size .size-wrap {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: (minmax(30rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}

.fquote .info .info-content .size .size-wrap .size-info {
  margin: 2rem;
}

.fquote .info .info-content .size .size-wrap .size-info input {
  font-size: 2rem;
  padding: 0.5rem 0.5rem;
  width: 8rem;
}

.fquote .info .info-content .size .size-wrap .size-info .wid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .icon {
  position: relative;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .icon .cross-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  cursor: pointer;
  position: absolute;
  left: -8px;
  top: 10px;
  background-color: #d25252;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .icon .cross-wrap .cross {
  font-size: 2rem;
  color: white;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .icon .rugicon {
  font-size: 16rem;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .inp {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 1rem;
}

.fquote .info .info-content .size .size-wrap .size-info .wid .inp span {
  font-size: 1.8rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len .input {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.fquote .info .info-content .size .size-wrap .size-info .len .input span {
  font-size: 1.8rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len .type {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 1rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len .type span {
  font-size: 1.8rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len .type select {
  font-size: 1.8rem;
  padding: 0.5rem;
  outline: none;
  line-height: 1.8rem;
}

.fquote .info .info-content .size .size-wrap .size-info .len .type select option {
  font-size: 1.5rem;
}

.fquote .info .info-content .size button {
  font-size: 2rem;
  color: #008f34;
  border: solid 1px #008f34;
  cursor: pointer;
  margin: 2rem;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.fquote .info .info-content .size button:hover {
  background-color: #008f34;
  color: #eee;
}

.fquote .info .info-content .about {
  margin-bottom: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}

.fquote .info .info-content .about .buttons {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 2rem;
}

.fquote .info .info-content .about .buttons button {
  font-size: 2rem;
  color: #008f34;
  border: solid 1px #008f34;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.fquote .info .info-content .about .buttons button:hover {
  background-color: #008f34;
  color: #eee;
}

.fquote .info .info-content .about .message {
  max-width: 60rem;
}

.fquote .info .info-content .about .message textarea {
  width: 100%;
  height: 10rem;
  padding: 1rem;
  -ms-flex-item-align: start;
      align-self: flex-start;
  background-color: transparent;
  font-size: 2rem;
}

.fquote .info .info-content .delivery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
  margin-bottom: 3rem;
}

.fquote .info .info-content .delivery span {
  font-size: 1.8rem;
}

.fquote .info .info-content .delivery .radio {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;
  gap: 3rem;
}

@media (max-width: 600px) {
  .fquote .info .info-content .delivery .radio {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.fquote .info .info-content .delivery .radio label {
  font-size: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 0.5rem;
}

.fquote .info .info-content .delivery .radio label input {
  color: #008f34;
}

.fquote .info .info-content .delivery .radio label input[type="radio"] {
  accent-color: #008f34;
}

.fquote .info .info-content .delivery hr {
  margin-top: 10px;
  margin-bottom: 10px;
  border: 0;
  border-top: 1px solid #c2db5d;
}

.fquote .info .info-content .delivery .check label {
  font-size: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 0.5rem;
}

.fquote .info .info-content .delivery .check label input {
  color: #008f34;
}

.fquote .info .info-content .delivery .check label input[type="checkbox"] {
  accent-color: #008f34;
}

.fquote .info .info-content .appointment {
  max-width: 40rem;
  margin-bottom: 3rem;
  font-size: 100% !important;
}

@media (max-width: 1050px) {
  .fquote .info .info-content .appointment {
    font-size: 100%;
  }
}

@media (max-width: 450px) {
  .fquote .info .info-content .appointment {
    font-size: 100%;
  }
}

.fquote .info .info-content .appointment input {
  font-size: 2rem;
  background-color: white;
}

.fquote .info .info-content .appointment .font {
  font-size: 100% !important;
}

.fquote .info .info-content .contact .contact-form {
  margin: 2rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 2rem;
}

.fquote .info .info-content .contact .contact-form label {
  color: #d25252;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 2rem;
}

@media (max-width: 500px) {
  .fquote .info .info-content .contact .contact-form label {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 1.5rem;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
}

.fquote .info .info-content .contact .contact-form label span {
  color: black;
  width: 20rem;
  font-size: 2rem;
}

.fquote .info .info-content .contact .contact-form label input {
  font-size: 2rem;
  width: 100%;
  padding: 1rem;
}

.fquote .info .info-content .submit {
  padding: 2rem;
}

.fquote .info .info-content .submit button {
  font-size: 3rem;
  font-weight: 300;
  color: #008f34;
  border: solid 1px #008f34;
  cursor: pointer;
  -webkit-transition: 0.5s all ease;
  transition: 0.5s all ease;
}

.fquote .info .info-content .submit button:hover {
  background-color: #008f34;
  color: #eee;
}
