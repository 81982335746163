@import "../variables/variables.scss";

.navbarM {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1050;
  transition: all ease 1s;

  @media (max-width: 850px) {
    background-color: white;
  }

  .nav-tab {
    padding: 0rem 7%;
    padding-bottom: 1rem;

    @media (max-width: 1200px) {
      padding: 0rem 3%;
      padding-bottom: 1rem;
    }
    @media (max-width: 800px) {
      padding: 0rem 2rem;
      padding-bottom: 1rem;
    }
  }

  .navbar_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;
    padding: 1rem 0 0 0rem;

    @media (max-width: 900px) {
      align-items: center;
      padding: 0.5rem 0 0 0rem;
    }

    .navbar_logo {
      img {
        width: 17rem;
        height: 17rem;

        @media (max-width: 800px) {
          width: 14rem;
          height: 14rem;
        }

        @media (max-width: 600px) {
          width: 10rem;
          height: 10rem;
        }
      }
    }

    .nav-content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      gap: 1rem;

      @media (max-width: 400px) {
        max-width: 25rem;
      }

      .nav-content-u {
        display: flex;
        align-items: center;
        gap: 2rem;
        color: $main;

        @media (max-width: 700px) {
          gap: 1rem;
        }

        .nav-icon-wrapper {
          font-size: 2.8rem;
          display: flex;
          align-items: center;
          gap: 1rem;

          span {
            font-size: 3rem;
            display: flex;
            @media (max-width: 850px) {
              display: none;
            }
          }

          .nav-icon {
            background-color: #217e37;
            position: relative;
            text-align: center;
            height: 38px;
            width: 38px;
            line-height: 38px;
            border-radius: 50%;
            margin-right: 20px;
            animation: pulse2 infinite 2s;

            svg {
              top: 7px;
              left: 8px;
              color: white;
              // background: $main-l;
              position: relative;
              display: block;
              font-size: 2.2rem;
              @media (max-width: 450px) {
                top: 9px;
                left: 10px;
              }
            }

            &::after {
              content: "";
              height: 58px;
              width: 58px;
              // border: 1px solid #d7d7d7;
              border-radius: 50%;
              position: absolute;
              top: -9px;
              left: -10px;
              animation: pulse2 infinite 2s;
            }
          }
        }

        @keyframes pulse2 {
          0% {
            box-shadow: 0 0 0 0 #008f34;
          }

          40% {
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
          }
          70% {
            box-shadow: 0 0 0 20px rgba(0, 143, 52, 0);
          }
          100% {
            box-shadow: 0 0 0 0 rgba(0, 143, 52, 0);
          }
        }

        button {
          cursor: pointer;
          background-color: $main;
          padding: 1.4rem 3rem;
          border: none;
          color: white;
          font-size: 2.8rem;
          border-radius: 5rem;

          @media (max-width: 500px) {
            display: none;
            font-size: 2.5rem;
            border-radius: 2rem;
            padding: 1.4rem 2rem;
          }
        }

        svg {
          font-size: 3rem;
          display: none;

          @media (max-width: 900px) {
            display: block;
          }
        }
      }

      .nav-content-d {
        @media (max-width: 900px) {
          display: none;
        }

        ul {
          display: flex;
          list-style-type: none;

          a {
            text-transform: uppercase;
            .sublink {
              position: relative;
            }

            .nav-list.active {
              transform: scaleY(1);
              opacity: 1;
              visibility: visible;
            }

            .nav-list {
              border-top: 2px solid $main;
              background-color: white;
              box-shadow: 0 3px 20px rgba(0, 0, 0, 0.05);
              display: flex;
              left: 0;
              flex-direction: column;
              position: absolute;
              top: 100%;
              z-index: 11;
              list-style-type: none;
              visibility: hidden;
              transform: scaleY(0);
              transform-origin: 0 0 0;
              opacity: 0;
              transition: all 0.5s ease;
              width: 24rem;
              gap: 0;

              a {
                padding: 0.5rem 1rem;
                font-weight: 400;
                border-bottom: solid 1px #e9e9e9;
                transition: all ease 0.5s;
                text-transform: capitalize;

                &:hover {
                  padding-left: 4rem;

                  .icon {
                    transform: scale(1);
                    margin-right: 1rem;
                  }
                }

                li {
                  display: flex;
                  align-items: center;
                  padding: 0;
                  font-size: 1.5rem;

                  .icon {
                    position: absolute;
                    left: 20px;
                    transform: scale(0);
                    transition: all 0.5s ease 0s;
                    font-size: 1.5rem;
                    color: $main;
                  }

                  .icon.active {
                    transform: scale(1);
                    margin-right: 1rem;
                  }
                }

                &:hover {
                  li {
                    color: $main;
                  }
                }
              }
            }
          }

          a {
            font-weight: 550;
            li {
              font-size: 1.7rem;
              color: black;
              padding: 1.5rem;

              &:hover {
                color: $main;
              }
            }
          }
          .link.active {
            li {
              color: $main;
            }
          }
        }
      }
    }
  }

  .MeanNav {
    .mean-nav-list.active {
      max-height: 60rem;
    }

    .mean-nav-list {
      display: none;
      list-style-type: none;
      max-height: 0;
      overflow: hidden;
      transition: max-height 0.7s ease-in-out;

      @media (max-width: 900px) {
        display: block;
      }

      .link.active {
        li {
          color: $main;
        }
      }

      a {
        li {
          padding: 1rem 0rem;
          color: black;
          font-weight: 600;
          border-top: dashed #ddd 1px;

          .rightIcon {
            color: $main;
            padding-right: 1rem;
          }
        }

        .plus {
          display: flex;
          align-items: center;
          justify-content: space-between;

          div {
            color: black;
            font-weight: 600;
            font-size: inherit;
          }

          .plusicon {
            font-weight: 600;
            font-size: 2.5rem;
          }
        }

        .sub-mean {
          list-style-type: none;
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.7s ease-in-out;
          a {
            li {
              padding-left: 5rem;
            }
          }
        }

        .sub-mean.servi {
          max-height: 60rem;
        }

        .sub-mean.why {
          max-height: 60rem;
        }
      }
    }
  }
}
.navbarM.active {
  top: 0;
  opacity: inherit;
  overflow: visible;
}

#scroll-container {
  border-radius: 5px;
  overflow: hidden;
  white-space: nowrap;
  background-color: red;
}

#scroll-text {
  text-align: left;
  font-size: 2.3rem;
  color: yellow;
  transform: translateX(-100%);
  animation: my-animation 20s linear infinite;
  @media (max-width: 500px) {
    animation: my-animation-mob 15s linear infinite;
    // display: none;
  }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(-90%);
  }
}

@keyframes my-animation-mob {
  from {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(-180%);
  }
}
