@import "../../common/variables/variables.scss";

.not-found {
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 25rem;
  margin-bottom: 5rem;

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-size: 15rem;
      background-image: url(./space3.avif);
      background-clip: text;
      margin-bottom: 2rem;
      -webkit-text-fill-color: transparent;
    }

    h3 {
      color: black;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    a {
      font-size: 3rem;

      span {
        font-size: inherit;
        text-decoration: underline;
        color: $blue;
      }
    }
  }
}

.navMain {
  background-color: #eee;
}
