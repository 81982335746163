@import "../../common/variables/variables.scss";

.feedback {
  padding: 8rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .heading {
    h2 {
      color: $main-l;
    }
  }

  .form {
    padding-top: 3rem;
    form {
      textarea {
        width: 100%;
        height: 20rem;
        font-size: 2.5rem;
        outline: none;
        padding: 1rem;
      }

      input {
        margin-top: 2rem;
        font-size: 2.3rem;
        cursor: pointer;
        padding: 1rem;
        background-color: $main-l;
        color: white;
        outline: none;
        border: none;
      }
    }
  }
}
