@import "../../common/variables/variables.scss";

.faq {
  padding: 5rem 12%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .faq-heading {
    text-align: center;
    margin-bottom: 4rem;
    h1 {
      margin-bottom: 1rem;
      font-size: 4rem;
      color: $blue;
    }

    h2 {
      font-size: 4rem;
    }
  }

  .lists {
    .questions {
      margin-bottom: 3rem;
      .question {
        border: solid 0.5px $lgray;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 1s ease;
        padding: 2rem 3rem;

        &.active {
          background-color: $main-l;
          color: white;

          svg {
            color: $main;
            border: none;
          }
        }

        h4 {
          font-size: 2.5rem;
          font-weight: 400;

          @media (max-width: 500px) {
            font-size: 2rem;
          }
        }

        svg {
          font-size: 3.5rem;
          margin-left: 2rem;
          border-radius: 100%;
          border: 2px solid $lgray;
          color: white;
          background-color: $blue;
        }
      }

      .answer {
        border: solid 0.5px rgb(187, 237, 246);
        max-height: 0px;
        overflow: hidden;
        transition: max-height 1s linear;
        width: 100%;
        h4 {
          font-weight: 400;
          padding: 2rem 3rem;
          font-size: 2.5rem;

          @media (max-width: 500px) {
            font-size: 2rem;
          }
        }
      }
      .answer.active {
        margin-bottom: 2rem;
        max-height: 60rem;
      }
    }
  }
}
