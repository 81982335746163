@import "../../common/variables/variables.scss";

.reviews .slide p {
  padding: 1.5rem;
  padding-bottom: 4rem;
  background: #eee;
  position: relative;
  margin-bottom: 3rem;
  font-size: 1.6rem;
  color: #666;
  line-height: 2;
}

.reviews {
  .heading {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: #4a4a4c;
    border-bottom: 5px solid $main-l;
    margin-bottom: 7rem;

    h1 {
      font-size: 4rem;
      padding: 4rem 2rem;
      color: white;
    }

    img {
      width: 10rem;
      height: 7rem;
      margin-right: 5rem;
    }
  }

  .review-content {
    grid-gap: 4rem;
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

    padding: 2rem 10rem;
    margin-bottom: 3rem;
    @media (max-width: 800px) {
      padding: 2rem 2rem;
      gap: 4rem;
    }
    .content-box {
      text-align: center;

      p {
        // text-align: center;
        font-size: 2.5rem;
        min-height: 25rem;
        @media (max-width: 650px) {
          padding-bottom: 1rem;
          min-height: fit-content;
        }
      }

      span {
        color: $blue;
        font-size: 2.3rem;
      }

      .icons {
        margin-top: 1rem;
        svg {
          font-size: 2.2rem;
          color: gold;
        }
      }
    }
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    .more-review {
      background-color: $main-l;
      padding: 2rem 2rem;
      margin-top: 1rem;
      border-bottom-left-radius: 3rem;
      border-top-right-radius: 3rem;
      text-align: center;
      span {
        width: fit-content;
        color: white;
        text-align: center;
        font-size: 2.5rem;
      }
    }
  }
}

.reviews .slide p::before {
  content: "";
  position: absolute;
  bottom: -1rem;
  left: 2rem;
  height: 2rem;
  width: 2rem;
  background: #eee;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.reviews .slide .user {
  /* display: -webkit-box;
    display: -ms-flexbox; */
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 1.5rem;
}

.reviews .slide .user img {
  height: 7rem;
  width: 7rem;
}

.reviews .slide .user h3 {
  font-size: 2.5rem;
  color: #eee;
  text-transform: capitalize;
}

.reviews .slide .user .stars {
  padding-top: 0.5rem;
}

.reviews .slide .user .stars i {
  font-size: 1.4rem;
  color: #f5bf23;
}
