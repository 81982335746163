@import "../../common/variables/variables.scss";

.change-slide {
  background: url(../../../Assets/Images/NDIS/ndis-banner.webp);
  background-size: cover;
  background-position: center;
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 3%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .container-1 {
    margin-bottom: 4rem;
    h2 {
      text-align: center;
      font-size: 4rem;
      color: $main-l;
      margin-bottom: 2rem;
    }
    p {
      text-align: center;
    }
  }
  .container-2 {
    h2 {
      text-align: center;
      font-size: 4rem;
      color: $main-l;
      margin-bottom: 2rem;
    }
  }

  .container-3 {
    .switch {
      margin-bottom: 4rem;
      gap: 1rem;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      .box {
        cursor: pointer;
        min-width: 17rem;
        background-color: white;
        // max-height: 15rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 0.5rem;
        padding: 2rem;
        img {
          width: 8rem;
          height: 7rem;
          margin-bottom: 2rem;
          @media (max-width: 600px) {
            width: 6rem;
            height: 5rem;
          }
        }

        p {
          text-align: center;
          color: black;
        }

        &.active {
          background-color: #c2db5d;
        }
      }
    }

    .content {
      //   cursor: grab;
      //   max-height: 50rem;
      margin: 3rem;
      @media (max-width: 600px) {
        margin: 0;
      }
      display: flex;
      align-items: center;
      background-color: $main-l;
      .info {
        flex: 1;
        padding: 5rem 4rem;
        display: flex;
        align-items: center;
        flex-direction: column;
        h2 {
          text-align: center;
          color: white;
          margin-bottom: 2rem;
        }

        p {
          color: white;
          text-align: center;
          margin-bottom: 3rem;
        }

        a {
          display: flex;
          align-items: center;
          border: 1px solid white;
          border-radius: 4rem;
          width: fit-content;

          &:hover {
            animation-name: btn1;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
          }

          span {
            font-size: 2.2rem;
            margin-right: 2rem;
            margin-left: 1rem;
            color: white;
          }
          img {
            width: 7rem;
            height: 5rem;
          }
        }
      }

      .img {
        @media (max-width: 1080px) {
          display: none;
        }
        // max-width: 50%;
        clip-path: circle(65% at 65% 50%);
        img {
          max-height: 50rem;
        }
      }
    }
  }
}

.ndis-main-info {
  padding: 5rem 13%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .container-1-wrapper,
  .container-3-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 3rem;
    margin-bottom: 3rem;

    @media (max-width: 500px) {
      flex-direction: column-reverse;
    }

    .container-1,
    .container-3 {
      .heading {
        margin-bottom: 2rem;
        p {
          font-weight: 550;
        }
      }

      .content {
        display: inline;
        span {
          font-size: inherit;
          color: $main-l;
        }
        img {
          width: 30rem;
          float: right;
          padding: 2rem;

          @media (max-width: 500px) {
            float: right;
            padding-left: 2rem;
            width: 100%;
          }
        }
      }

      ul {
        margin-left: 3rem;
      }
    }

    .container-3-img {
      margin-top: 4rem;
      img {
        width: 30rem;
      }
    }

    .container-1-img {
      @media (max-width: 500px) {
        margin-top: 0rem;
      }
      margin-top: 4rem;
      img {
        // width: 30rem;
      }
    }
  }

  .container-2-wrapper {
    margin-bottom: 3rem;
    .heading {
      margin-bottom: 1rem;
      p {
        font-weight: 550;
      }
    }
  }
}

.ndis-img {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .img-1 {
    margin-bottom: 3rem;
    img {
      width: 100%;
    }
  }

  .img-2 {
    img {
      width: 100%;
    }
    h2 {
      color: $main-l;
      text-align: center;
      margin-top: 4rem;
      margin-bottom: 2rem;
    }
  }
}

.rug-service {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 3rem 2rem;
  }
  grid-gap: 2rem;
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
  align-items: center;

  .rug-service-img {
    h2 {
      display: none;
      text-align: center;
      margin-bottom: 4rem;
      color: $main;
      font-size: 3.5rem;
      @media (max-width: 580px) {
        display: block;
      }
    }
    h4 {
      display: none;
      color: black;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 3.5rem;
      margin-bottom: 3rem;
      @media (max-width: 580px) {
        display: block;
      }
    }
    img {
      width: 100%;
    }
  }

  .rug-service-content {
    h2 {
      margin-top: 2rem;
      margin-bottom: 1rem;
      color: $main;
      font-size: 3.5rem;
      @media (max-width: 580px) {
        display: none;
      }
    }
    h4 {
      color: black;
      margin-bottom: 1rem;
      font-size: 3.5rem;
      @media (max-width: 580px) {
        display: none;
      }
    }
    p {
      margin-bottom: 3rem;
      line-height: 3rem;
    }
    h3 {
      color: #c2db5d;
      font-size: 3rem;
      font-weight: 500;
      // margin-bottom: 4rem;
    }

    a {
      display: flex;
      align-items: center;
      border: 1px solid rgb(1, 137, 177);
      border-radius: 4rem;
      width: fit-content;

      &:hover {
        animation-name: btn1;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
        animation-iteration-count: 1;
      }

      span {
        font-size: 2.2rem;
        margin-right: 2rem;
        margin-left: 1rem;
      }
      img {
        width: 7rem;
        height: 5rem;
      }
    }
  }
}

.rug-service.reverse {
  align-items: flex-start;

  .rug-service-img {
    h2 {
      display: none;
      text-align: center;
      margin-bottom: 1rem;
      color: $main;
      font-size: 3.5rem;
      @media (max-width: 580px) {
        display: block;
      }
    }
    h4 {
      display: none;
      color: black;
      text-align: center;
      margin-bottom: 1rem;
      font-size: 3.5rem;
      margin-bottom: 3rem;
      @media (max-width: 580px) {
        display: block;
      }
    }
  }
  .rug-service-content {
    h4 {
      color: black;
      margin-bottom: 1rem;
      font-size: 3.5rem;
      @media (max-width: 580px) {
        display: none;
      }
    }

    p {
      display: inline;
      @media (max-width: 580px) {
        display: flex;
        flex-direction: column;
      }
      // display: flex;

      img {
        float: right;
        margin: 5px 1px 5px 0;
        max-width: 30rem;
        @media (max-width: 580px) {
          max-width: 100%;
          float: left;
        }
      }
    }
  }
}

.stain {
  background: url(../../../Assets/Images/Banner/pet-banner.webp);
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 3%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }
  .heading {
    text-align: center;
    h1 {
      font-size: 3.5rem;
      color: $main-l;
      margin-bottom: 2rem;
    }

    h2 {
      font-size: 3.5rem;
      margin-bottom: 3rem;
    }

    p {
      margin-bottom: 4rem;
    }
  }

  .stain-wrapper {
    display: flex;
    justify-content: center;

    @media (max-width: 1000px) {
      align-items: center;
    }

    @media (max-width: 700px) {
      gap: 2rem;
      flex-direction: column;
    }

    .main-info {
      margin-left: 17rem;
      // width: 50%;
      flex: 1;
      img {
        max-width: 60rem;
        @media (max-width: 1000px) {
          width: 100%;
        }
      }

      @media (max-width: 1450px) {
        margin-left: 12rem;
      }

      @media (max-width: 1000px) {
        margin-left: 0rem;
      }
    }

    .icon-info {
      flex: 1;

      @media (max-width: 800px) {
        width: 100%;
      }

      .icon-box-wrapper {
        position: relative;
        margin-bottom: 1rem;

        .icon-box1 {
          position: relative;
          left: -12rem;
          @media (max-width: 1000px) {
            top: 0;
            left: 0;
          }
        }

        .icon-box2 {
          position: relative;
          left: -8.5rem;
          @media (max-width: 1000px) {
            top: 0;
            left: 0;
          }
        }

        .icon-box3 {
          position: relative;
          left: -16rem;
          @media (max-width: 1000px) {
            top: 0;
            left: 0;
          }
        }

        .icon-box4 {
          position: relative;
          left: -30rem;
          top: -5rem;
          @media (max-width: 1550px) {
            top: -3rem;
          }
          @media (max-width: 1000px) {
            top: 0;
            left: 0;
          }
        }

        .icon-box1,
        .icon-box2,
        .icon-box3,
        .icon-box4 {
          display: flex;
          align-items: center;
          // margin-bottom: 2rem;

          &:hover {
            .icon {
              transform: scale(1.1);
            }
          }

          .icon {
            margin-right: 3rem;
            border-radius: 50%;
            background-color: #c2db5d;
            border: 1rem solid white;
            padding: 3rem;
            transition: all 0.5s ease;
            @media (max-width: 700px) {
              padding: 2rem;
              border: 0.5rem solid white;
              margin-right: 1rem;
            }

            img {
              width: 7rem;
              @media (max-width: 800px) {
                width: 5rem;
              }
            }
          }

          .info {
            h1 {
              color: $main-l;
              font-size: 2rem;
              margin-bottom: 0.5rem;
            }

            p {
              line-height: 2.5rem;
            }
          }
        }
      }
    }
  }
}

.threeD {
  background: url(../../../Assets/Images/Banner/3d-banner.webp);
  background-position: center;
  padding: 7rem 0;

  .wrapper {
    padding: 3rem;
    @media (max-width: 600px) {
      padding: 1rem;
    }
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      will-change: transform;
      transform: perspective(700px) rotateY(5deg);
      transition: transform 1s ease 0s;

      &:hover {
        transform: perspective(3000px) rotateY(-5deg);
      }

      .comment-box {
        img {
          max-width: 100%;
          @media (max-width: 400px) {
            min-height: 42rem;
          }
        }
      }

      .comment-info {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        padding: 0rem 1rem;
        top: 5rem;
        max-width: 58rem;

        h1 {
          color: #02285e;
          font-size: 7rem;
          font-weight: 900;
          @media (max-width: 600px) {
            font-size: 5rem;
          }
          @media (max-width: 400px) {
            font-size: 4rem;
          }
        }

        p {
          color: black;
          font-size: 2.5rem;
          margin-bottom: 4rem;
          @media (max-width: 600px) {
            margin-bottom: 2rem;
            font-size: 2rem;
          }
        }

        button {
          width: 23rem;
          background-color: transparent;
          padding: 0;
          padding-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid rgb(1, 137, 177);
          border-radius: 4rem;
          cursor: pointer;

          &:hover {
            animation-name: btn1;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
          }

          span {
            font-size: 2.2rem;
            margin-right: 1rem;
            margin-left: 1rem;
          }
          img {
            width: 7rem;
            height: 5rem;
          }
        }
      }
    }
  }
}

.benefits {
  padding: 5rem 10%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  width: 100%;
  background-image: url(../../../Assets/Images/Banner/benifits-of-cleaning.webp);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;

  .benefits-wrapper {
    padding-left: 40%;

    @media (max-width: 1200px) {
      padding-left: 0;
    }
    .benefits-heading {
      margin-bottom: 4rem;
      h2 {
        text-transform: uppercase;
        color: $main;
        font-size: 3rem;
        margin-bottom: 2rem;
      }

      h1 {
        text-transform: uppercase;
        color: black;
        font-size: 3rem;
      }
    }

    .benefits-content {
      grid-gap: 3rem;
      display: grid;
      gap: 3rem;
      grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));

      @media (max-width: 1200px) {
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      }

      .benefit-box {
        display: flex;
        align-items: flex-start;
        gap: 2rem;

        .benefit-img {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: white;
          padding: 1.5rem;
          min-height: 8rem;
          min-width: 8rem;
          border-radius: 50%;
          box-shadow: 0px 0px 8px 4px rgba(3, 3, 3, 0.2);
          transition: all 0.5s ease;

          &:hover {
            transform: scale(1.2);
          }
          img {
            width: 4rem;
            //   width: 8rem;
          }
        }

        .benefit-info {
          h4 {
            color: $main-l;
            font-size: 2rem;
            text-transform: uppercase;
            margin-bottom: 1rem;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
