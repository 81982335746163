@import "../../common/variables/variables.scss";

.location-comp {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .heading {
    margin-bottom: 3rem;
    h2 {
      color: $main-l;
      text-align: center;
      font-size: 4rem;
    }
  }

  .content {
    p {
      margin-bottom: 1.5rem;
      strong {
        font-size: inherit;
      }
      a {
        color: $main-l;
      }
    }
  }

  .gallery-sub {
    padding: 0;
    padding-top: 3rem;
    p {
      font-size: 3.5rem;
      color: black;
      font-weight: 600;
      text-align: center;
      margin-bottom: 1rem;
    }

    .gallery-sub-title {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      margin-bottom: 2rem;
    }

    .gallery-sub-img {
      ._30i9K {
        grid-gap: 2rem;
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(auto-fit, minmax(35rem, 1fr));
      }
      img {
        width: 100%;
      }
    }
  }
}
