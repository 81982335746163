h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.blogs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding: 5rem 8%;
}

@media (max-width: 1200px) {
  .blogs {
    padding: 5rem 6%;
  }
}

@media (max-width: 800px) {
  .blogs {
    padding: 5rem 2rem;
  }
}

.blogs .blog.heading {
  margin-bottom: 3rem;
}

.blogs .blog.heading h2 {
  font-size: 4rem;
  color: #c047c0;
}

.blogs ul {
  margin-top: 5rem;
  -ms-flex-item-align: center;
      align-self: center;
  border: 1px #cbcbcb solid;
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
}

.blogs ul li {
  border-left: 1px #cbcbcb solid;
  color: #0d6efd;
  height: 100%;
  cursor: pointer;
  padding: 1rem 0rem;
}

.blogs ul li a {
  padding: 1.5rem 1.5rem;
}

.blogs ul .previous.disabled,
.blogs ul .next.disabled {
  color: #686868;
}

.blogs ul .selected {
  color: white;
  background-color: #0d6efd;
}

.blogs .blog.content {
  grid-gap: 2rem;
  display: -ms-grid;
  display: grid;
  gap: 4rem;
  -ms-grid-columns: (minmax(30rem, 1fr))[auto-fit];
      grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));
}

.blogs .blog.content .blog {
  -webkit-box-shadow: 0px 0px 7px -2px;
          box-shadow: 0px 0px 7px -2px;
  overflow: hidden;
  border-radius: 5px;
}

.blogs .blog.content .blog .blog_img {
  overflow: hidden;
}

.blogs .blog.content .blog .blog_img img {
  cursor: pointer;
  width: 100%;
  -webkit-transition: 0.5s linear;
  transition: 0.5s linear;
  max-height: 40rem;
}

.blogs .blog.content .blog .blog_img img:hover {
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}

.blogs .blog.content .blog .blog_info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  margin: 2rem;
  min-height: 12rem;
}

.blogs .blog.content .blog .blog_info h4 {
  color: #008f34;
  font-size: 2.5rem;
  min-height: 9rem;
}

@media (max-width: 800px) {
  .blogs .blog.content .blog .blog_info h4 {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    margin-bottom: 1rem;
  }
}

.blogs .blog.content .blog .blog_info .extras {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.blogs .blog.content .blog .blog_info .extras span {
  gap: 1rem;
  font-size: 1.8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  color: gray;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.blogs .blog.content .blog .blog_info .extras span svg {
  font-size: 2rem;
  color: #008f34;
}

.blogs .blog.content .blog .blog_info .desc {
  min-height: 13rem;
}

@media (max-width: 800px) {
  .blogs .blog.content .blog .blog_info .desc {
    min-height: -webkit-fit-content;
    min-height: -moz-fit-content;
    min-height: fit-content;
    margin-bottom: 2rem;
  }
}

.blogs .blog.content .blog .blog_info .desc p {
  text-align: start;
  font-size: 2.3rem;
  line-height: 3.5rem;
}

.blogs .blog.content .blog .blog_info a {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #008f34;
}

.blogs .blog.content .blog .blog_info a .button {
  gap: 0.5rem;
  font-size: 2rem;
}

.blogs .blog.content .blog .blog_info a .button svg {
  font-size: 1.8rem;
}
