h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}

p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@-webkit-keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

@keyframes btn1 {
  16.65% {
    -webkit-transform: translateX(8px);
            transform: translateX(8px);
  }
  33.3% {
    -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
  }
  49.95% {
    -webkit-transform: translateX(4px);
            transform: translateX(4px);
  }
  66.6% {
    -webkit-transform: translateX(-2px);
            transform: translateX(-2px);
  }
  83.25% {
    -webkit-transform: translateX(1px);
            transform: translateX(1px);
  }
  100% {
    -webkit-transform: translateX(0);
            transform: translateX(0);
  }
}

.why_us {
  padding: 5rem 12%;
}

@media (max-width: 1200px) {
  .why_us {
    padding: 5rem 7%;
  }
}

@media (max-width: 800px) {
  .why_us {
    padding: 5rem 2rem;
  }
}

.why_us .heading {
  margin-bottom: 2rem;
}

.why_us .heading h4 {
  color: #008f34;
  font-size: 4rem;
}

.why_us .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}

.why_us .content p {
  margin-bottom: 1.8rem;
  line-height: 3rem;
}

.why_us .content p span {
  color: #217e37;
  font-size: inherit;
  cursor: pointer;
}

.why_us .content ul {
  list-style-type: none;
}

.why_us .content ul li {
  line-height: 3rem;
  margin-bottom: 1rem;
}

.why_us .content ul li svg {
  margin-right: 1.5rem;
  color: #217e37;
  font-size: 1.4rem;
}
