@import "../../common/variables/variables.scss";

.locations {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .heading {
    margin-bottom: 3rem;
    h2 {
      color: $main;
      text-transform: uppercase;
      font-size: 3.5rem;
    }
  }
  .content {
    .container {
      margin-bottom: 2rem;
      svg {
        margin-right: 1rem;
        font-size: 2.5rem;
      }

      span {
        color: $main-l;
        font-size: 2.5rem;
      }
    }
  }
}
