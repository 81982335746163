@import "../variables/variables.scss";

.tubeQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
  @media (max-width: 900px) {
    display: none;
  }

  iframe {
    width: 50rem;
    height: 35rem;

    @media (max-width: 600px) {
      width: 40rem;
      height: 35rem;
    }
  }
  h2 {
    color: black;
    font-size: 3.5rem;
    margin-bottom: 3rem;
  }
}

.contactQ {
  padding: 2rem;
  margin-right: 10rem;
  background-color: #fff;
  .contact.content {
    display: flex;
    align-items: flex-start;
    gap: 2rem;

    @media (max-width: 750px) {
      flex-direction: column;
    }

    .contact.img {
      width: 50%;
      img {
        width: 100%;
      }

      @media (max-width: 750px) {
        display: none;
      }
    }

    .contact.info {
      @media (max-width: 750px) {
        width: 100%;
      }

      h2 {
        color: $main-l;
        font-size: 3.5rem;
        margin-bottom: 1rem;
      }

      h1 {
        color: black;
        font-size: 3.5rem;
        margin-bottom: 3rem;
      }

      form {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        input {
          width: 100%;
          background-color: white;
          height: 2rem;
          padding: 2.5rem 0;
          font-size: 2rem;
          padding-left: 1rem;
          border: 1px solid #ccc;
          outline: none;
          border-radius: 5px;
        }

        textarea {
          padding: 1rem 0;
          background-color: white;
          height: 8rem;
          font-size: 2rem;
          padding-left: 1rem;
          border: 1px solid #ccc;
          outline: none;
          border-radius: 5px;
        }

        button {
          width: 18rem;
          background-color: transparent;
          padding: 0;
          padding-left: 1rem;
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid rgb(1, 137, 177);
          border-radius: 4rem;
          cursor: pointer;

          &:hover {
            animation-name: btn1;
            animation-duration: 1s;
            animation-timing-function: ease-in-out;
            animation-iteration-count: 1;
          }

          span {
            font-size: 2.2rem;
            margin-right: 2rem;
            margin-left: 1rem;
            color: black;
          }
          img {
            width: 7rem;
            height: 5rem;
          }
        }

        .name {
          display: flex;
          gap: 2rem;

          .block {
            display: flex;
            width: 100%;
            flex-direction: column;
          }
        }
      }
    }
  }
}

.g-recaptcha {
  transform: scale(0.6);
  transform-origin: 0 0;
}
