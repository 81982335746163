@import "../../common/variables/variables.scss";

.contactMain {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  display: flex;
  align-items: center;
  gap: 2rem;

  @media (max-width: 700px) {
    gap: 5rem;
    flex-direction: column;
  }

  .form-wrapper {
    width: 130%;

    @media (max-width: 700px) {
      width: 100%;
    }
    h2 {
      font-size: 3.5rem;
      margin-bottom: 3rem;

      span {
        font-size: 3.5rem;
        color: $main-l;
      }
    }
    form {
      display: flex;
      flex-direction: column;
      gap: 2rem;
      input {
        width: 100%;
        background-color: white;
        height: 2rem;
        padding: 2.5rem 0;
        font-size: 2rem;
        padding-left: 1rem;
        border: 1px solid #ccc;
        outline: none;
        border-radius: 5px;
      }

      textarea {
        padding: 1rem 0;
        background-color: white;
        height: 15rem;
        font-size: 2rem;
        padding-left: 1rem;
        border: 1px solid #ccc;
        outline: none;
        border-radius: 5px;
      }

      button {
        width: 18rem;
        background-color: transparent;
        padding: 0;
        padding-left: 1rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid rgb(1, 137, 177);
        border-radius: 4rem;
        cursor: pointer;

        &:hover {
          animation-name: btn1;
          animation-duration: 1s;
          animation-timing-function: ease-in-out;
          animation-iteration-count: 1;
        }

        span {
          font-size: 2.2rem;
          margin-right: 2rem;
          margin-left: 1rem;
          color: black;
        }
        img {
          width: 7rem;
          height: 5rem;
        }
      }

      .name {
        display: flex;
        gap: 2rem;

        .block {
          width: 100%;
        }
      }

      a {
        margin-top: 1rem;
        font-size: 1.8rem;
        color: blue;
      }
    }
  }

  .info-wrapper {
    width: 70%;

    @media (max-width: 700px) {
      width: 100%;
    }
    h2 {
      font-size: 3.5rem;
      margin-bottom: 3rem;
      color: $main;
    }

    .info-p {
      margin-bottom: 4rem;
      line-height: 3rem;
    }

    .info-box {
      margin-bottom: 3rem;
      display: flex;
      align-items: flex-start;
      .icon {
        svg {
          font-size: 3rem;
          color: $main-l;
          margin-right: 3rem;
        }
      }

      .content {
        span {
          font-size: 2.5rem;
          color: black;
        }
        p {
          margin-top: 1rem;
          font-size: 2.2rem;
          color: $main-l;
        }
      }
    }
  }
}

.melborne {
  padding: 5rem 15%;

  @media (max-width: 1200px) {
    padding: 5rem 7%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  display: flex;
  align-items: center;
  flex-direction: column;
  h1 {
    text-align: center;
    color: $main-l;
    font-size: 3.5rem;
    margin-bottom: 4rem;
  }

  img {
    max-width: 70rem;
    padding: 1rem;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}
