@import "../../common/variables/variables.scss";

.blogs {
  display: flex;
  flex-direction: column;
  padding: 5rem 8%;

  @media (max-width: 1200px) {
    padding: 5rem 6%;
  }
  @media (max-width: 800px) {
    padding: 5rem 2rem;
  }

  .blog.heading {
    margin-bottom: 3rem;
    h2 {
      font-size: 4rem;
      color: $purple;
    }
  }

  ul {
    margin-top: 5rem;
    align-self: center;
    border: 1px rgb(203, 203, 203) solid;
    list-style-type: none;
    display: flex;
    width: max-content;

    li {
      border-left: 1px rgb(203, 203, 203) solid;
      color: #0d6efd;
      height: 100%;
      cursor: pointer;
      padding: 1rem 0rem;

      a {
        padding: 1.5rem 1.5rem;
      }
    }

    .previous.disabled,
    .next.disabled {
      color: #686868;
    }

    .selected {
      color: white;
      background-color: #0d6efd;
    }
  }

  .blog.content {
    grid-gap: 2rem;
    display: grid;
    gap: 4rem;
    grid-template-columns: repeat(auto-fit, minmax(30rem, 1fr));

    .blog {
      box-shadow: 0px 0px 7px -2px;
      overflow: hidden;
      border-radius: 5px;

      .blog_img {
        overflow: hidden;
        img {
          cursor: pointer;
          width: 100%;
          transition: 0.5s linear;
          max-height: 40rem;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .blog_info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 2rem;
        min-height: 12rem;

        h4 {
          color: $main-l;
          font-size: 2.5rem;
          min-height: 9rem;
          @media (max-width: 800px) {
            min-height: fit-content;
            margin-bottom: 1rem;
          }
        }

        .extras {
          display: flex;
          gap: 1rem;
          margin-bottom: 2rem;
          span {
            gap: 1rem;
            font-size: 1.8rem;
            display: flex;
            color: gray;
            align-items: center;
            svg {
              font-size: 2rem;
              color: $main-l;
            }
          }
        }

        .desc {
          min-height: 13rem;
          @media (max-width: 800px) {
            min-height: fit-content;
            margin-bottom: 2rem;
          }
          p {
            text-align: start;
            font-size: 2.3rem;
            line-height: 3.5rem;
          }
        }

        a {
          width: fit-content;
          color: $main-l;
          .button {
            gap: 0.5rem;
            font-size: 2rem;

            svg {
              font-size: 1.8rem;
            }
          }
        }
      }
    }
  }
}
