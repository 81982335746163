$nav-bg: rgba(0, 0, 0, 0.5);
$dgray: rgb(86, 86, 86);
$paracolor: #7a7a7a;
$whatsapp: #4dc247;
$lgray: #e1e1e1;
$main: #217e37;
$main-l: #008f34;
$orange: #ffb400;
$orange-l: #ffcf60;
$bald: rgb(232, 240, 254);
$purple: rgb(192, 71, 192);
$purple-l: rgb(210, 114, 210);
$blue: #6ec1e4;
$greenyellow: #c2db5d;

h2 {
  font-size: 3rem;
}

h3 {
  font-size: 2.5rem;
  color: #ffcf60;
}

h1 {
  font-size: 4rem;
  color: #ffb400;
}
p,
li {
  color: #686868;
  font-size: 2rem;
  line-height: 3rem;
  text-align: justify;
  text-justify: inter-word;
}

image {
  width: 100%;
}

@mixin pad20 {
  padding: 10rem 20%;
}

@mixin pad10 {
  padding: 7rem 10%;
}

// @import "../../common/variables/variables.scss";

@keyframes btn1 {
  16.65% {
    transform: translateX(8px);
  }

  33.3% {
    transform: translateX(-6px);
  }
  49.95% {
    transform: translateX(4px);
  }
  66.6% {
    transform: translateX(-2px);
  }
  83.25% {
    transform: translateX(1px);
  }
  100% {
    transform: translateX(0);
  }
}
